import React, { useState, useEffect } from 'react'
import RequestQuote from './RequestQuote';
import { Link } from 'react-router-dom'
import logo from './assets/logoecom.png';
import seo from './assets/seo.jpg'
import UserFriendly from './assets/UserFriendly.png'
import unlimitedProduct from './assets/unlimitedproductcategories.png'
import storepickup from './assets/storepickup.jpg'
import securePayment from './assets/securepayment.png'
import socialmedia from './assets/socialmedia.jpg'
import giftcard from './assets/giftcard.png'
import logoecom from './assets/logoecom.png'
import inventoryManagment from './assets/inventorymanagement.jpg'
import liveNotification from './assets/livenotification.jpg'
import service from './assets/247service.jpg'
import responsive from './assets/responsive.jpg'
import dashboard from './assets/dashboard.jpg'
import Footer from './Footer';
import StepsSlider from './StepsSlider';
import ModalBox from './ModalBox';
import PricingForm from './PricingForms';
import axios from 'axios';
import video from './assets/KuchBhiOrder.com.mp4'
import kuchbhiorder from './assets/kuchbhiorder.png'
import uniquevalues from './assets/uniquevalues.gif'
import home from './assets/home.gif'
import about from './assets/aboutus.gif'
import pricing from './assets/Pricing.gif'
import home1 from './assets/home.gif'
import ownership from './assets/ownership.gif'
function LandingPage() {

    const [showModal, setShowModal] = useState(false);

    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const [mediaFiles, setMediaFiles] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // Manage current index
    const [isPlayingVideo, setIsPlayingVideo] = useState(false); // Track if video is playing
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState("");
    const words = ["CREATE", "CUSTOMIZE", "LAUNCH"];
    const baseText = "Your Online Store with KuchBhiOrder.com";

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentLetterIndex((prevIndex) => {
                // If current word is fully displayed, move to the next word
                if (prevIndex < words[currentWordIndex].length - 1) {
                    return prevIndex + 1;
                } else {
                    // Reset for the next word
                    setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                    return 0; // Reset to the first letter of the new word
                }
            });
        }, 300); // Change letter every 300ms

        return () => clearInterval(interval);
    }, [currentWordIndex, words]);

    // Fetch media files from the server
    useEffect(() => {
        const fetchMediaFiles = async () => {
            try {
                const response = await axios.get('https://besto-ecommrce.onrender.com/media');
                setMediaFiles(response.data);
            } catch (error) {
                console.error('Error fetching media files:', error);
            }
        };
        fetchMediaFiles();
    }, []);

    // Handle video start and end events
    const handleVideoStarted = () => {
        setIsPlayingVideo(true); // Pause the carousel auto-slide when video starts
    };

    const handleVideoEnded = () => {
        setIsPlayingVideo(false); // Resume auto-slide after video ends
        setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaFiles.length); // Move to the next slide
    };

    // Automatically slide images when not playing video
    useEffect(() => {
        if (!isPlayingVideo) {
            const timer = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaFiles.length); // Cycle through all media
            }, 3000); // Change every 3 seconds

            return () => clearInterval(timer); // Cleanup on unmount
        }
    }, [isPlayingVideo, mediaFiles.length]);


    return (
        <div>

            {/* #HEADER */}


            <header className="header" data-header>
                <div className="container">
                    <div className="overlay" data-overlay></div>

                    <a href="#">
                        <img src={kuchbhiorder} className='logo' />
                    </a>

                    <nav className="navbar" data-navbar>
                        <div className="navbar-top">
                            <a href="#home" className="logo">Ted Martins</a>

                            <button
                                className="nav-close-btn"
                                aria-label="Close Menu"
                                data-nav-close-btn
                            >
                                <ion-icon name="close-outline"></ion-icon>
                            </button>
                        </div>

                        <ul className="navbar-list">
                            {/* <li className="navbar-item">
                                <a href="#home" className="navbar-link" data-navbar-link>Home</a>
                            </li> */}

                            <li className="navbar-item">
                                <a href="#about" className="navbar-link" data-navbar-link>About</a>
                            </li>

                            <li className="navbar-item">
                                <a href="#Resources" className="navbar-link" data-navbar-link>Resources</a>
                            </li>

                            <li className="navbar-item">
                                <a href="#Pricing" className="navbar-link" data-navbar-link
                                >Pricing</a
                                >
                            </li>

                            <li className="navbar-item">
                                <a href="#features" className="navbar-link" data-navbar-link
                                >Features</a
                                >
                            </li>

                            {/* <li className="navbar-item">
                                <a href="#blog" className="navbar-link" data-navbar-link>Blog</a>
                            </li> */}

                            {/* <li className="navbar-item">
                                <a href="#contact" className="navbar-link" data-navbar-link>Contact Us</a>
                            </li> */}
                        </ul>
                    </nav>

                    <a href="#quoteRequest" className="btn">
                        <ion-icon name="chevron-forward-outline" aria-hidden="true"></ion-icon>

                        <span>Get A Quote</span>
                    </a>

                    <button className="nav-open-btn" aria-label="Open Menu" data-nav-open-btn>
                        <ion-icon name="menu-outline"></ion-icon>
                    </button>
                </div>
            </header>

            <main>
                <article>
                    {/* #HERO */}


                    <section className="hero" id="home">
                        <div className="container">
                            <div className="hero-content">
                                {/* <p className="hero-subtitle">We Are Product Designer From UK</p> */}
                                {/* <p className="hero-subtitle">CREATE, CUSTOMIZE, and LAUNCH Your Online Store with</p>

                                <h2 className="h2 hero-title">KuchBhiOrder.com</h2> */}
                                <h2 className="hello-world">
                                    <span className="additional-text">
                                        {words[currentWordIndex].split("").map((letter, index) => (
                                            <span
                                                key={index}
                                                className="letter"
                                                style={{
                                                    opacity: currentLetterIndex >= index ? 1 : 0,
                                                    transition: "opacity 0.3s ease-in-out",
                                                    animation: currentLetterIndex >= index ? 'fadeIn 0.5s forwards' : 'none',
                                                    animationDelay: `${index * 0.1}s`, // Staggered effect
                                                }}
                                            >
                                                {letter}
                                            </span>
                                        ))}
                                    </span>
                                    <span className="base-text"> {baseText}</span>
                                </h2>
                                {/* <p className="hero-text">
                                        Morbi sed lacus nec risus finibus feugiat et fermentum nibh.
                                        Pellentesque vitae ante at elit fringilla ac at purus.
                                    </p> */}




                                <button className="btn">Learn More</button>
                            </div>

                            <figure className="hero-banner">
                                <img
                                    src={home1}
                                    width="694"
                                    height="529"
                                    loading="lazy"
                                    alt="hero-banner"
                                    className="w-100 banner-animation"
                                />
                            </figure>
                        </div>
                    </section>

                    {/* #ABOUT */}


                    <section className="section about" id="about">
                        <div className="container">
                            <figure className="about-banner">
                                <img
                                    src={home}
                                    width="700"
                                    height="532"
                                    loading="lazy"
                                    alt="about banner"
                                    className="w-100 banner-animation"
                                />
                            </figure>

                            <div className="about-content">
                                <h2 className="h2 section-title underline">About us</h2>

                                <p className="about-text">
                                    Welcome to KuchBhiOrder.com, an easy to use platform that empowers entrepreneurs and businesses to build their own e-commerce stores effortlessly. Our mission is to simplify the process of setting up an online business by providing a comprehensive and user-friendly platform for creating, customizing, and managing e-commerce solutions. Whether you're a startup, a small business owner, or an established retailer, KuchBhiOrder.com offers the tools and flexibility needed to bring your online store to life. </p>
                                <p className="about-text">We believe in the power of digital transformation and aim to make e-commerce accessible to everyone. Our platform is designed with features that cater to various business needs, from product management and payment integration to Inventory Management.  </p>
                                <p className="about-text">Join us on this journey to redefine online shopping and make your e-commerce vision a reality. At KuchBhiOrder.com, we do more than just enable product sales; we empower you to establish an Online Store Effortlessly.
                                </p>



                                {/* <ul className="stats-list">
                                        <li className="stats-card">
                                            <p className="h3 stats-title">9875</p>

                                            <p className="stats-text">Satisfied Clients</p>
                                        </li>

                                        <li className="stats-card">
                                            <p className="h3 stats-title">7894</p>

                                            <p className="stats-text">Project Lunched</p>
                                        </li>

                                        <li className="stats-card">
                                            <p className="h3 stats-title">65</p>

                                            <p className="stats-text">Years Completed</p>
                                        </li>
                                    </ul> */}
                            </div>
                        </div>
                    </section>


                    {/* why choose us */}
                    <section className="section about">
                        <div className="container">
                            <div className="about-content">


                                <h2 className="h2 section-title underline">Our Unique Value</h2>

                                <p className="about-text"><strong>Quick and Easy Store Setup:</strong> Our platform allows you to set up your online store in just a few clicks. Simply add your products and inventory details, and you're ready to start selling.</p>
                                <p className="about-text"><strong>Domain and Hosting Included:</strong> We offer domain and hosting services tailored to your needs, so you can launch your online store without any extra hassle.</p>
                                <p className="about-text"><strong>Unlimited Product Listings:</strong> Easily add an unlimited number of products and create customized categories to organize your store effectively.</p>
                                <p className="about-text"><strong>Live Notifications:</strong> Keep your customers engaged with instant notifications for new product launches, sales, and other important updates.</p>
                                <p className="about-text"><strong>Go Global:</strong> Launch your store online and break geographical barriers, reaching customers across the globe.</p>
                                <p className="about-text"><strong>Flexible Payment Options:</strong> Offer your customers a variety of secure payment methods to enhance their shopping experience and increase sales.</p>
                                <p className="about-text"><strong>Exclusive Support for Premium Members:</strong> Our premium members receive additional assistance with inventory management, delivery models, digital marketing, and more to help grow their business.</p>


                            </div>
                            <figure className="about-banner">
                                <img
                                    src={uniquevalues}
                                    width="700"
                                    height="532"
                                    loading="lazy"
                                    alt="about banner"
                                    className="w-100 banner-animation"
                                />
                            </figure>


                        </div>
                    </section>


                    {/* #Pricing */}

                    <section className="section features" id="Pricing">
                        <div className="container">
                            <h2 className="h2 section-title underline">Our Pricing</h2>

                            <ul className="features-list">
                                <li>
                                    <div className="features-card">
                                        <div className="icon">
                                            <ion-icon name="bulb-outline"></ion-icon>
                                        </div>

                                        <div className="content" onClick={handleModalShow}>
                                            <h3 className="h3 title">Standard Plan</h3>

                                            <h3 className="h3 title">
                                                Price: ₹ 20 / Month
                                            </h3>
                                            <p className='text'>
                                                <ul class="google-bullets">
                                                    <li>24x7 Live Phone and Chat Support</li>
                                                    <li>Unlimited Products and Users</li>
                                                    <li>Abandoned Cart Emails</li>
                                                    <li>Advanced Product Filter</li>
                                                    <li>Flexible Payment Options</li>
                                                    <li>Store Pick Up Facility</li>
                                                    <li>Detailed Dashboard</li>
                                                </ul>
                                            </p>
                                            <br />
                                            <button

                                                className='btn'
                                                aria-label="Show More"
                                            >
                                                Register Now
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                {/* <li>
                                    <div className="features-card">
                                        <div className="icon">
                                            <ion-icon name="color-palette-outline"></ion-icon>
                                        </div>

                                        <div className="content">
                                            <h3 className="h3 title">Designing</h3>

                                            <p className="text">
                                                Praesent tincidunt congue est ut hendrerit. Pellentesque et
                                                eros sit amet ipsum venenatis.
                                            </p>
                                        </div>
                                    </div>
                                </li> */}
                            </ul>

                            <figure className="features-banner">
                                <img
                                    src={pricing}
                                    width="369"
                                    height="318"
                                    loading="lazy"
                                    alt="Features Banner"
                                    className="w-100 banner-animation"
                                />
                            </figure>

                            {/* <ul className="features-list">
                                <li>
                                    <div className="features-card">
                                        <div className="icon">
                                            <ion-icon name="code-slash-outline"></ion-icon>
                                        </div>

                                        <div className="content">
                                            <h3 className="h3 title">Development</h3>

                                            <p className="text">
                                                Praesent tincidunt congue est ut hendrerit. Pellentesque et
                                                eros sit amet ipsum venenatis.
                                            </p>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="features-card">
                                        <div className="icon">
                                            <ion-icon name="rocket-outline"></ion-icon>
                                        </div>

                                        <div className="content">
                                            <h3 className="h3 title">Testing & Lunching</h3>

                                            <p className="text">
                                                Praesent tincidunt congue est ut hendrerit. Pellentesque et
                                                eros sit amet ipsum venenatis.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul> */}

                            <ul className="features-list">
                                <li>
                                    <div className="features-card">
                                        <div className="icon">
                                            <ion-icon name="bulb-outline"></ion-icon>
                                        </div>

                                        <div className="content">
                                            <h3 className="h3 title">Custom Plan</h3>

                                            <h3 className="h3 title">
                                                Price: Get A Quote
                                            </h3>
                                            <p className='text'>
                                                <ul class="google-bullets">
                                                    <li>Fully customizable features and services to match your business requirements</li>
                                                    <li>Personalized support and consulting</li>
                                                    <li>Flexible integrations and tools</li>
                                                    <li>Scalable solutions for large enterprises</li>
                                                    <li>Any additional features you need to achieve your business goals</li>
                                                </ul>

                                            </p>
                                            <br />
                                            <a href="#quoteRequest" className="btn">
                                                Get A Quote
                                            </a>
                                        </div>
                                    </div>
                                </li>

                                {/* <li>
                                    <div className="features-card">
                                        <div className="icon">
                                            <ion-icon name="color-palette-outline"></ion-icon>
                                        </div>

                                        <div className="content">
                                            <h3 className="h3 title">Designing</h3>

                                            <p className="text">
                                                Praesent tincidunt congue est ut hendrerit. Pellentesque et
                                                eros sit amet ipsum venenatis.
                                            </p>
                                        </div>
                                    </div>
                                </li> */}
                            </ul>

                            {showModal && (
                                <div className="modal-overlay">
                                    <div className="modal">
                                        <div className="modal-header">
                                            <h3>Fill the Form</h3>
                                            <button
                                                className="close-button"
                                                onClick={handleModalClose}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <ModalBox />
                                        </div>
                                        <div className="modal-footer"></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>

                    {/* #features */}

                    <section className="section blog" id="features">
                        <div className="container">
                            <h2 className="h2 section-title underline">Features of Kuch Bhi Order</h2>

                            <ul className="blog-list">
                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={UserFriendly}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Vestibulum massa arcu, consectetu pellentesque scelerisque."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    User-Friendly Interface
                                                </a>
                                            </h3>

                                            <p className="text">
                                                Our platform is designed with simplicity in mind, allowing users of all technical backgrounds to navigate and manage their stores effortlessly. The intuitive dashboard makes it easy to update products, track sales, and view analytics at a glance.
                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">7 December, 2023</time>
                                                </div> */}

                                                {/* <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="1">1</data>
                                                </button> */}

                                                {/* <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={unlimitedProduct}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Quisque egestas iaculis felis eget placerat ut pulvinar mi."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Unlimited Products and Categories
                                                </a>
                                            </h3>

                                            <p className="text">
                                                Scale your business without limitations. Kuch Bhi Order allows you to add an unlimited number of products and create customized categories to help your customers find what they’re looking for quickly and easily.

                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">3 August, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={securePayment}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Fusce sem ligula, imperdiet sed nisi sit amet, euismod posuere."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Secure Payment Gateway
                                                </a>
                                            </h3>

                                            <p className="text">
                                                Provide peace of mind to your customers with a secure payment gateway that supports multiple payment options. Ensure safe transactions with advanced encryption, making online shopping a secure experience.
                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">29 April, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={inventoryManagment}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Inventory Management
                                                </a>
                                            </h3>

                                            <p className="text">

                                                Efficiently track your stock levels and manage inventory with our powerful tools. Receive alerts for low stock, easily update product details, and avoid overselling or stockouts, all from a centralized dashboard.

                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">16 March, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                {/*next slider*/}
                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={giftcard}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Fusce sem ligula, imperdiet sed nisi sit amet, euismod posuere."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Gift Cards as Discounts
                                                </a>
                                            </h3>

                                            <p className="text">

                                                Increase customer loyalty by offering redeemable gift cards as discounts for future purchases. This feature encourages repeat business and provides customers with an incentive to return to your store.                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">29 April, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={storepickup}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Fusce sem ligula, imperdiet sed nisi sit amet, euismod posuere."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Store Pickup Facility
                                                </a>
                                            </h3>

                                            <p className="text">

                                                Enhance customer convenience with the option for store pickup. Customers can select this option during checkout, allowing them to collect their orders directly from your physical location, saving on shipping costs.
                                            </p>
                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">29 April, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={storepickup}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Fusce sem ligula, imperdiet sed nisi sit amet, euismod posuere."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Seamless Connectivity with Customers
                                                </a>
                                            </h3>

                                            <p className="text">
                                                Foster strong relationships with your customers through integrated communication tools. Utilize email marketing, live chat, and personalized messaging to keep customers informed and engaged throughout their shopping journey.
                                            </p>
                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">29 April, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={liveNotification}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Live Notifications
                                                </a>
                                            </h3>

                                            <p className="text">
                                                Keep your customers engaged with live notifications for new product launches, special promotions, and important updates. This feature helps drive sales and enhances customer interaction, keeping your audience informed and excited.
                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">16 March, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>


                                    </div>
                                </li>

                                {/*next third slider*/}
                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={seo}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    SEO-Friendly
                                                </a>
                                            </h3>

                                            <p className="text">


                                                Optimize your online store for search engines with built-in SEO tools. Improve your site’s visibility and attract more customers by optimizing product descriptions, meta tags, and URLs for better search engine rankings.

                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">16 March, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>


                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={responsive}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Mobile Responsive
                                                </a>
                                            </h3>

                                            <p className="text">
                                                Ensure your store provides an excellent shopping experience on all devices. Our mobile-responsive design adjusts seamlessly to smartphones and tablets, allowing customers to shop anywhere, anytime.
                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">16 March, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>


                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={dashboard}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    Analytics and Reporting
                                                </a>
                                            </h3>

                                            <p className="text">
                                                Gain valuable insights into your store’s performance with comprehensive analytics and reporting tools. Track sales trends, customer behavior, and inventory levels to make informed decisions that drive growth.
                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">16 March, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">
                                        <figure className="banner">
                                            <a href="#">
                                                <img
                                                    src={service}
                                                    width="750"
                                                    height="350"
                                                    loading="lazy"
                                                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                                                    className="img-cover"
                                                />
                                            </a>
                                        </figure>

                                        <div className="content">
                                            <h3 className="h3 title">
                                                <a href="#">
                                                    24/7 Customer Support
                                                </a>
                                            </h3>

                                            <p className="text">

                                                Our dedicated support team is available around the clock to assist you with any queries or issues. Whether you need technical support or guidance on using our features, we’re here to help you succeed.                                            </p>

                                            <div className="meta">
                                                {/* <div className="publish-date">
                                                    <ion-icon name="time-outline"></ion-icon>

                                                    <time dateTime="2022-03-07">16 March, 2023</time>
                                                </div>

                                                <button className="comment" aria-label="Comment">
                                                    <ion-icon name="chatbubble-outline"></ion-icon>

                                                    <data value="15">15</data>
                                                </button>

                                                <button className="share" aria-label="Share">
                                                    <ion-icon name="share-social-outline"></ion-icon>
                                                </button> */}
                                            </div>
                                        </div>


                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <div className='container dataownership'>
                        <div className='datarow'>
                            <div className='col-md-6'>
                            <h2 className="h2 section-title underline">Your store and data belong to you, Always</h2>
                                <p className="text">Including control over where your data is stored and how it can be used.</p>
                            </div>
                            <div className='col-md-4'>
                                <img
                                    src={ownership}
                                    width="750"
                                    height="300"
                                    loading="lazy"
                                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                                    className="img-cover"
                                />
                            </div>
                        </div>


                    </div>


                    <section className="section blog" id="Resources">
                        <StepsSlider />
                        <video
                            controls
                            autoPlay
                            onPlay={handleVideoStarted} // Pause auto-slide on video play
                            onEnded={handleVideoEnded}  // Move to next slide when video ends
                            width="100%"
                        >
                            <source
                                src={video}
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    </section>


                    {/* get request quote */}

                    <section className="section about" id="quoteRequest">
                        <div className="container">
                            <figure className="about-banner">
                                <img
                                    src="https://img.freepik.com/free-vector/flat-design-office-printer-illustration_23-2150268487.jpg?t=st=1729313974~exp=1729317574~hmac=37505a2661aeacd9ed32fa73d02c69f0fa1378f904fe0a8f95e2cf0416636b71&w=740"
                                    width="700"
                                    height="532"
                                    loading="lazy"
                                    alt="about banner"
                                    className="w-100 banner-animation"
                                />
                            </figure>

                            <div className="about-content">
                                <h2 className="h2 section-title underline">Request A Quote</h2>
                                <RequestQuote />
                            </div>
                        </div>
                    </section>

                </article>
            </main>










            {/* #FOOTER */}




            {/* #GO TO TOP */}


            <a href="#top" className="go-top active" aria-label="Go To Top" data-go-top>
                {/* <ion-icon name="arrow-up-outline"></ion-icon> */}
                ↑
            </a>


        </div>
    )
}

export default LandingPage