import React from 'react'
import { Link } from 'react-router-dom'
import kuchbhiorder from './assets/kuchbhiorder.png'


function Footer({ currentYear }) {
    return (
        <div>
            <div className="footer-top section">
                <div className="container">
                    <div className="footer-brand">
                        <img src={kuchbhiorder} className='logo' />

                        <p className="address">

                        </p >

                        <ul className="social-list">
                            <li>
                                <a href="#" className="social-link">
                                    <ion-icon name="logo-facebook"></ion-icon>
                                </a>
                            </li>

                            <li>
                                <a href="#" className="social-link">
                                    <ion-icon name="logo-instagram"></ion-icon>
                                </a>
                            </li>

                            <li>
                                <a href="#" className="social-link">
                                    <ion-icon name="logo-twitter"></ion-icon>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <ul className="footer-list">
                        <li>
                            <p className="footer-list-title">Our links</p>
                        </li>

                        <li>
                            <a href="#home" className="footer-link">Home</a>
                        </li>

                        <li>
                            <a href="#about" className="footer-link">About Us</a>
                        </li>

                        <li>
                            <a href="#Resources" className="footer-link">Resources</a>
                        </li>

                        <li>
                            <a href="#Pricing" className="footer-link">Pricing</a>
                        </li>

                        <li>
                            <a href="#features" className="footer-link">Features</a>
                        </li>
                    </ul>

                    {/* <ul className="footer-list">
                            <li>
                                <p className="footer-list-title"></p>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>
                        </ul> */}

                    <ul className="footer-list">
                        <li>
                            <p className="footer-list-title">Other links</p>
                        </li>

                        <li>
                            <Link to='/privacy' className="footer-link">Privacy Policy</Link>
                        </li>

                        <li>
                            <Link to='/tandc' className="footer-link">Terms & Conditions</Link>
                        </li>

                        <li>
                            <Link to='/refundpolicy' className="footer-link">Refund Policy</Link>
                        </li>
                    </ul>

                    <ul className="footer-list">
                        <li>
                            <p className="footer-list-title">Contact Us</p>
                        </li>

                        <li className="footer-item">
                            <div className="footer-item-icon">
                                <ion-icon name="call"></ion-icon>
                            </div>

                            <div>
                                <p className="footer-item-link"
                                >+91 9423529427</p
                                >
                                <p className="footer-item-link"
                                >+91 9272090926</p
                                >
                            </div>
                        </li>

                        <li className="footer-item">
                            <div className="footer-item-icon">
                                <ion-icon name="mail"></ion-icon>
                            </div>

                            <div>
                                <p className="footer-item-link"
                                >contact@kuchbhiorder.com</p>
                                <p href="#" className="footer-item-link"
                                >sales@kuchbhiorder.com</p>
                            </div>
                        </li>

                        <li className="footer-item">
                            <div className="footer-item-icon">
                                <ion-icon name="location"></ion-icon>
                            </div>

                            <address className="footer-item-link">
                                First floor, Above CSB Bank, Opp.Thyssenkrupp Industry, Near Ambedkar Chowk, Pimpri,
                                Pune - Maharashtra.                                </address>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="footer-bottom">
                <p className="copyright">
                    {/* &copy; 2023 <a href="#" className="copyright-link">Ted Martins</a>. All
                            Right Reserved */}
                    <small>Copyright © {currentYear}.Bhalchandraya Innovative Services Private Limited. All Rights Reserved.</small>

                </p>
            </div>
        </div>
    )
}

export default Footer