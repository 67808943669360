// import React, { useState, useEffect } from "react";
// import addproduct from './assets/steps/addproducts.png'
// import domain from './assets/steps/domain.jpg'
// import engage from './assets/steps/engage.png'
// import launch from './assets/steps/lauch.jpg'
// import payment from './assets/steps/payment.jpg'
// import setupstore from './assets/steps/setupstore.jpg'
// import signup from './assets/steps/signup.jpg'

// const StepsSlider = () => {
//   const cards = [
//     { id: 1, src:signup,  title: "1. Sign Up", heading: "1. Sign Up", text: " Create your account on KuchBhiOrder.com by filling out a simple registration form." },
//     { id: 2, src:domain, title: "2. Choose Your Domain", heading: "2. Choose Your Domain", text: "Select a unique domain name that represents your brand and purchase it through our platform." },
//     { id: 3, src:setupstore, title: "3. Set Up Your Store", heading: "3. Set Up Your Store", text: "Use our intuitive interface to customize your store’s design, layout, and branding to reflect your vision" },
//     { id: 4, src:addproduct, title: "4. Add Products", heading: "4. Add Products", text: "Upload your products, including images, descriptions, prices, and inventory details. Organize them into categories for easy navigation." },
//     { id: 5, src:payment, title: "5. Configure Payment Options", heading: "5. Configure Payment Options", text: "Choose from various secure payment methods to provide flexibility for your customers during checkout." },
//     { id: 6, src:launch, title: "6. Launch Your Store", heading: "6. Launch Your Store", text: "Once everything is set up, go live and start promoting your store to attract customers." },
//     { id: 7, src:engage, title: "7. Engage with Customers", heading: "7. Engage with Customers", text: "Utilize live notifications to keep your customers informed about new products, sales, and updates." },
//   ];

//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       nextCards();
//     }, 3000); // Change image every 3 seconds

//     return () => clearInterval(timer); // Clean up the timer on component unmount
//   }, [currentIndex]);

//   const nextCards = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
//   };

//   const previousCards = () => {
//     setCurrentIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
//   };

//   // Calculate the displayed cards
//   const displayedCards = [
//     cards[(currentIndex + 0) % cards.length],
//     cards[(currentIndex + 1) % cards.length],
//     cards[(currentIndex + 2) % cards.length],
//   ];

//   return (
//     <div className="container cardsinfo">
//       <div className="card-display">
//         <div className="card-container">
//           {displayedCards.map((card) => (
//             <div key={card.id} className="card">
//               <img src={card.src} alt={card.title} className="card-image" />
//               <h3 className="card-heading">{card.heading}</h3>
//               <p className="card-text">{card.text}</p>
//             </div>
//           ))}
//         </div>
//         <div className="button-container">
//           <button onClick={previousCards} className="btn">
//             Previous
//           </button>
//           <button onClick={nextCards} className="btn">
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StepsSlider;


import React, { useState, useEffect } from "react";
import addproduct from './assets/steps/addproducts.png'
import domain from './assets/steps/domain.png'
import engage from './assets/steps/engage.png'
import launch from './assets/steps/launch.png'
import payment from './assets/steps/payment.png'
import setupstore from './assets/steps/setupstore.png'
import signup from './assets/steps/signup.png'

const StepsSlider = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const words = ["CREATE", "CUSTOMIZE", "LAUNCH"];
  const baseText = "Your Online Store with KuchBhiOrder.com";

  const items = [
    { id: 1, src: signup, title: "Sign Up", text: "Create your account on KuchBhiOrder.com by filling out a simple registration form." },
    { id: 2, src: domain, title: "Choose Your Domain", text: "Select a unique domain name that represents your brand and purchase it through our platform." },
    { id: 3, src: setupstore, title: "Set Up Your Store", text: "Use our intuitive interface to customize your store’s design, layout, and branding to reflect your vision." },
    { id: 4, src: addproduct, title: "Add Products", text: "Upload your products, including images, descriptions, prices, and inventory details. Organize them into categories for easy navigation." },
    { id: 5, src: payment, title: "Configure Payment Options", text: "Choose from various secure payment methods to provide flexibility for your customers during checkout." },
    { id: 6, src: launch, title: "Launch Your Store", text: "Once everything is set up, go live and start promoting your store to attract customers." },
    { id: 7, src: engage, title: "Engage with Customers", text: "Utilize live notifications to keep your customers informed about new products, sales, and updates." },
  ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setDisplayedText((prevText) => {
  //       // If current word is fully displayed, move to the next word
  //       if (currentLetterIndex < words[currentWordIndex].length) {
  //         return words[currentWordIndex].slice(0, currentLetterIndex + 1);
  //       } else {
  //         // Reset for the next word
  //         setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
  //         setCurrentLetterIndex(0);
  //         return ""; // Clear the text to start the new word
  //       }
  //     });
  
  //     // Move to the next letter
  //     setCurrentLetterIndex((prevIndex) => prevIndex + 1);
  //   }, 600); // Change letter every 600ms for slower effect
  
  //   return () => clearInterval(interval);
  // }, [currentLetterIndex, currentWordIndex, words]);
  

  return (
    <div className="container">
      {/* <h2 className="hello-world">
        <span className="additional-text">
          {displayedText.split("").map((letter, index) => (
            <span
              key={index}
              className="letter"
              style={{ opacity: 1, transition: "opacity 0.3s ease-in-out" }}
            >
              {letter}
            </span>
          ))}
          {currentLetterIndex >= words[currentWordIndex].length && (
            <span>&nbsp;</span>
          )}
        </span>
        <span className="base-text"> {baseText}</span>
      </h2> */}
      {/* <div className="grid-container">
        {items.map((item) => (
          <div
            key={item.id}
            className={`grid-item ${item.id === 5 ? "move-to-second-column" : ""}`}
          >
            <img src={item.src} alt={item.title} className="grid-image" />
            <div className="grid-content">
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div> */}
      <h2 className="h2 section-title underline">Start Your Online Store </h2>
      <h4 className="stepssubheading">With These Simple Steps:</h4>
      <div className="grid-container">
  {items.map((item) => (
    <div
      key={item.id}
      className={`grid-item ${item.id === 7? "move-to-second-column" : ""}`}
    >
      <img src={item.src} alt={item.title} className="grid-image" />
      <div className="grid-content">
        <div className="grid-number">Step {item.id}</div> {/* Add this for the number */}
        <h3>{item.title}</h3>
        <p>{item.text}</p>
      </div>
    </div>
  ))}
</div>

    </div>
  );
};

export default StepsSlider;