import React from 'react'
import { Link,useNavigate } from 'react-router-dom'

function TandC() {
    const currentYear = new Date().getFullYear()
    const navigate = useNavigate();

    const goToHomePage = () => {
      navigate('/');  // Navigate to the home page (root URL)
    };
  return (


    <div>
    {/*header section*/}
    <main>
        <article>
            {/* #HERO */}


            <section className="hero" id="home">
                <div className="container">
                    <div className="hero-content">
                        {/* <p  className="hero-subtitle">We Are Product Designer From UK</p > */}
                        {/* <p  className="hero-subtitle">How Kuchbhiorder handles your data</p > */}
                        {/* <h2 className="h2 hero-title">We Design Interfaces That Users Love</h2> */}
                        <h2 className="h2 hero-title">Terms of Service </h2>

                        {/* <p  className="hero-text">
                                Morbi sed lacus nec risus finibus feugiat et fermentum nibh.
                                Pellentesque vitae ante at elit fringilla ac at purus.
                            </p > */}
      <button className="btn" onClick={goToHomePage}>Back To Home</button>
      </div>

                    <figure className="hero-banner">
                        <img
                            src="https://raw.githubusercontent.com/codewithsadee/desinic/master/assets/images/hero-banner.png"
                            width="694"
                            height="529"
                            loading="lazy"
                            alt="hero-banner"
                            className="w-100 banner-animation"
                        />
                    </figure>
                </div>
            </section>

       
<div>
<section className='section privacy termscondition'>

<div>
        <div>
   <p className="about-text">
     <strong><u>Terms of Service</u></strong>
   </p>
  <p className="about-text">&nbsp;</p>
     <p className="about-text">
    Welcome to
    <strong>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED</strong>! By
    signing up for a <strong>Kuchbhiorder.com</strong> Account (as defined in
     Section 1) or by using any <strong>Kuch bhi order.com</strong> Services (as
     defined below), you are agreeing to be bound by the following terms and
     conditions (the &ldquo;<strong>Terms of Service</strong>&rdquo;).
   </p>
      <p className="about-text">
     As used in these Terms of Service, &ldquo;<strong>we</strong>&rdquo;,
     &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo; and
     &ldquo;<strong>Kuch bhi order dot com</strong>&rdquo; means the applicable
     <strong>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED</strong>
     Contracting Party (as defined in Section 13 below), and
     &ldquo;<strong>you</strong>&rdquo; means the
     <strong>Kuchbhiorder.com</strong> User (if registering for or using a
     <strong>Kuchbhiorder</strong> Service as an individual), or the business
     employing the
     <strong>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED</strong> User (if
    registering for or using a <strong>Kuchbhiorder</strong> Service as a
     business) and any of its affiliates.
   </p>
   <p className="about-text">
     <strong>Kuchbhiorder</strong> provides a complete commerce platform that
     enables merchants to unify their commerce activities. Among other features,
    this platform includes a range of tools for merchants to build and customize
     online stores, sell in multiple places (including web, mobile, social media,
     online marketplaces and other online locations (&ldquo;<strong
      >Online Services</strong
    >&rdquo;) and in person (&ldquo;<strong>POS Services</strong>&rdquo;)),
    manage products, inventory, payments, fulfillment, shipping, business
    operations, marketing and advertising, and engage with existing and
    potential customers. Any such service or services offered by
    <strong>Kuchbhiorder</strong> are referred to in these Terms of Services as
    the &ldquo;<strong>Service(s)</strong>&rdquo;. Any new features or tools
    which are added to the current Services will also be subject to the Terms of
    Service.
  </p>
  <p className="about-text">
    You must read, agree with and accept all of the terms and conditions
    contained or expressly referenced in these Terms of Service, including
    <strong>Kuchbhiorder</strong>.
  </p>
  <p className="about-text">
    <strong
      >Everyday language summaries are provided for convenience only and appear
      in bold near each section, but these summaries are not legally binding.
      Please read the Terms of Service, including any document referred to in
      these Terms of Service, for the complete picture of your legal
      requirements. By using Kuchbhiorder</strong
    >
    <strong>or any Kuchbhiorder</strong>
    <strong
      >services, you are agreeing to these terms. Be sure to occasionally check
      back for updates.</strong
    >
  </p>
  <p className="about-text"><strong>1. Account Terms</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    You are responsible for your Account, the Materials you upload to the
    <strong>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED</strong> Service
    and the operation of your <strong>Kuchbhiorder</strong> Store. If you
    violate <strong>Kuchbhiorder</strong> terms of service we may cancel your
    service access. If we need to reach you, we will communicate via the Primary
    Email Address.
  </p>
  <ol>
    <li>
      To access and use the Services, you must register for a
      <strong>Kuchbhiorder</strong> account
      (&ldquo;<strong>Account</strong>&rdquo;). To complete your Account
      registration, you must provide us with your full legal name, business
      address, phone number, a valid email address, and any other information
      indicated as required. <strong>Kuchbhiorder</strong> may reject your
      application for an Account, or cancel an existing Account, for any reason,
      at our sole discretion.
    </li>
    <li>
      You must be the older of: (i) 18 years, or (ii) at least the age of
      majority in the jurisdiction where you reside and from which you use the
      Services to open an Account.
    </li>
    <li>
      You confirm that you are receiving any Services provided by
      <strong>Kuchbhiorder</strong> for the purposes of carrying on a business
      activity and not for any personal, household or family purpose.
    </li>
    <li>
      You acknowledge that <strong>Kuchbhiorder</strong> will use the email
      address you provide on opening an Account or as updated by you from time
      to time as the primary method for communication with you (&ldquo;<strong
        >Primary Email Address</strong
      >&rdquo;). You must monitor the Primary Email Address you provide to
      <strong>Kuchbhiorder</strong> and your Primary Email Address must be
      capable of both sending and receiving messages. Your email communications
      with <strong>Kuchbhiorder</strong> can only be authenticated if they come
      from your Primary Email Address.
    </li>
    <li>
      You are responsible for keeping your password secure.
      <strong>Kuchbhiorder</strong> cannot and will not be liable for any loss
      or damage from your failure to maintain the security of your Account and
      password. We may request additional security measures at any time and
      reserve the right to adjust these requirements at our discretion.
    </li>
    <li>
      Technical support in respect of the Services is only provided to
      <strong>Kuchbhiorder</strong> Users. Questions about the Terms of Service
      should be sent to <strong>Kuchbhiorder</strong> Support.
    </li>
    <li>
      You agree not to reproduce, duplicate, copy, sell, resell or exploit any
      portion of the Service, use of the Services, or access to the Services
      without the express written permission by <strong>Kuchbhiorder</strong>.
    </li>
    <li>
      You agree not to work around, bypass, or circumvent any of the technical
      limitations of the Services, including to process orders outside
      <strong>Kuchbhiorder</strong> Checkout, use any tool to enable features or
      functionalities that are otherwise disabled in the Services, or decompile,
      disassemble or otherwise reverse engineer the Services.
    </li>
    <li>
      You agree not to access the Services or monitor any material or
      information from the Services using any robot, spider, scraper, or other
      automated means.
    </li>
    <li>
      You understand that your Materials may be transferred unencrypted and
      involve (a) transmissions over various networks; and (b) changes to
      conform and adapt to technical requirements of connecting networks or
      devices. &ldquo;<strong>Materials</strong>&rdquo; means Your Trademarks,
      copyright content, any products or services you sell through the Services
      (including description and price), and any photos, images, videos,
      graphics, written content, audio files, code, information, or other data
      provided or made available by you or your affiliates to
      <strong>Kuchbhiorder</strong> or its affiliates.
    </li>
  </ol>
  <p className="about-text"><strong>2. Account Activation</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    Only one person can be the &ldquo;Store Owner&rdquo;, usually the person
    signing up for the <strong>Kuchbhiorder</strong> Service. The Store Owner is
    responsible for the Account, is bound by these Terms of Service and is
    responsible for the actions of others accessing the Account. If you sign up
    on behalf of your employer, your employer is the Store Owner responsible for
    your Account.
  </p>
  <p className="about-text">
    We automatically create certain accounts for you to accept payments. You are
    responsible for activating and deactivating these accounts and complying
    with their terms, which may be with various third parties, including but not
    limited to PayPal, Apple Pay, Google Payment and Shop Pay. Any domain you
    purchase through us will automatically renew unless you opt out.
  </p>
  <p className="about-text"><strong>2.1 Store Owner</strong></p>
  <ol>
    <li>
      Subject to Section 2.1.2, the person signing up for the Service by opening
      an Account will be the contracting party (&ldquo;<strong
        >Store Owner</strong
      >
      &rdquo;) for the purposes of our Terms of Service and will be the person
      who is authorized to use any corresponding Account we may provide to the
      Store Owner in connection with the Service. You are responsible for
      ensuring that the name of the Store Owner (including the legal name of the
      company that owns the Store, if applicable) is clearly visible on the
      Store&rsquo;s website.
    </li>
    <li>
      If you are signing up for the Services on behalf of your employer, your
      employer will be the Store Owner. If you are signing up for the Services
      on behalf of your employer, then you must use your employer-issued email
      address and you represent and warrant that you have the authority to bind
      your employer to our Terms of Service.
    </li>
    <li>
      Your <strong>Kuchbhiorder</strong> Store can only be associated with one
      Store Owner. A Store Owner may have multiple
      <strong>Kuchbhiorder</strong> Stores. You agree to use
      <strong>Kuchbhiorder</strong> Checkout for your store.
      &ldquo;<strong>Store</strong>&rdquo; means the online store (whether
      hosted by <strong>Kuchbhiorder</strong> or on a third-party website), or
      any storefront built on top of the Storefront API) or physical retail
      location(s) associated with the Account.
    </li>
  </ol>
  <p className="about-text"><strong>2.2 Staff Accounts</strong></p>
  <ol>
    <li>
      Based on your <strong>Kuchbhiorder</strong> pricing plan, you can create
      one or more staff accounts (&ldquo;<strong>Staff Accounts</strong>&rdquo;)
      allowing other people to access the Account. Each Staff Account must
      include a full legal name and a valid email account. With Staff Accounts,
      the Store Owner can set permissions and let other people work in their
      Account while determining the level of access by Staff Accounts to
      specific business information (for example, you can limit Staff Account
      access to sales information on the Reports page or prevent Staff Accounts
      from changing general store settings).
    </li>
    <li>
      The Store Owner is responsible for: (a) ensuring its employees, agents and
      subcontractors, including via Staff Accounts, comply with these Terms of
      Service; and (b) any breach of these Terms of Service by the Store
      Owner&rsquo;s employees, agents or subcontractors. The Store Owner
      acknowledges and agrees that Store Owner will be responsible for the
      performance of all of its obligations under the Agreement, regardless of
      whether it sublicenses or subcontracts any such obligations to any third
      party, including but not limited to any affiliates or subsidiaries of
      Store Owner.
    </li>
    <li>
      The Store Owner and the users under Staff Accounts are each referred to as
      a &ldquo;<strong>Kuchbhiorder</strong> <strong>User</strong>&rdquo;.
    </li>
  </ol>
  <p className="about-text">
    <strong>2.3 Razorpay Checkout and Kuchbhiorder</strong>
    <strong>Payments Accounts</strong>
  </p>
  <ol>
    <li>
      Upon completion of sign up for the Service,
      <strong>Kuchbhiorder</strong> will create a
      <strong>Razorpay&nbsp;</strong>Checkout account on your behalf, using your
      Primary Email Address. Depending on your location,
      <strong>Kuchbhiorder</strong> may also create a
      <strong>Kuchbhiorder</strong> Payments account on your behalf.
    </li>
    <li>
      You acknowledge that <strong>Razorpay&nbsp;</strong>Checkout and/or
      <strong>Kuchbhiorder</strong> Payments will be your default payments
      gateway(s) and that it is your sole responsibility as the Store Owner to
      activate and maintain these accounts. If you do not wish to keep either of
      the payment accounts active, it is your responsibility to deactivate them.
      For the avoidance of doubt, <strong>Razorpay&nbsp;</strong>Checkout is a
      Third Party Service, as defined in Section 9.7.1 of these Terms of
      Service.
    </li>
  </ol>
  <p className="about-text"><strong>2.6 Google Pay</strong></p>
  <ol>
    <li>
      Upon completion of sign up for the Service, if you have been enrolled in
      <strong>Kuchbhiorder</strong> Payments, <strong>Kuchbhiorder</strong> will
      also create a Google Pay account on your behalf. If you do not wish to
      keep your Google Pay account active, it is your responsibility to
      deactivate it. For the avoidance of doubt, Google Pay is a Third Party
      Service, as defined in Section 9.7.1 of these Terms of Service.
    </li>
    <li>
      If you use a Google Pay supported payment gateway and your customers have
      enabled Google Pay, customers may purchase goods and services from your
      Store using Google Pay.
    </li>
    <li>
      By using Google Pay on your Store, you are agreeing to be bound by
      the&nbsp;<a
        href="https://payments.developers.google.com/terms/sellertos"
       
        ><strong
          ><u
            ><span
              >Google Pay API Terms of Service</span
            ></u
          ></strong
        ></a
      >, as they may be amended by Google from time to time. If Google amends
      the Google Pay API Terms of Service, the amended and restated version will
      be posted here:&nbsp;<a
        href="https://payments.developers.google.com/terms/sellertos"
       
        > https://payments.developers.google.com/terms/sellertos</a>. Such amendments to the Google Pay API Terms of Service are effective as
      of the date of posting. Your continued use of Google Pay on your Store
      after the amended Google Pay API Terms of Service are posted constitutes
      your agreement to, and acceptance of, the amended Google Pay API Terms of
      Service. If you do not agree to any changes to the Google Pay API Terms of
      Service, deactivate your Google Pay account and do not continue to use
      Google Pay on your Store.
    </li>
  </ol>
  <p className="about-text"><strong>2.8 Amazon Pay</strong></p>
  <ol>
    <li>
      Upon completion of sign up for the Service, if you have been enrolled in
      <strong>Kuchbhiorder</strong> Payments and you have enabled Amazon Pay
      within your <strong>Kuchbhiorder</strong> Admin,
      <strong>Kuchbhiorder</strong> will also create an Amazon Pay account on
      your behalf. If you do not wish to keep your Amazon Pay account active, it
      is your responsibility to deactivate it. For the avoidance of doubt,
      Amazon Pay is a Third Party Service, as defined in Section 9.7.1 of these
      Terms of Service.
    </li>
    <li>
      If you use an Amazon Pay supported payment gateway and your customers have
      enabled Amazon Pay, customers may purchase goods and services from your
      Store using Amazon Pay.
    </li>
    <li>
      By using Amazon Pay on your Store, you are agreeing to be bound by
      the&nbsp;<a
        href="https://pay.amazon.com/help/201212430"
        style={{"text-decoration": "none"}}
        ><strong
          ><u
            ><span style={{"color": "#0563c1"}}
              >Amazon Payments, Inc. Customer Agreement</span
            ></u
          ></strong
        ></a
      >, as it may be amended by Amazon from time to time. If Amazon amends
      the&nbsp;<a
        href="https://pay.amazon.com/help/201212430"
        style={{"textDecoration": "none"}}
        ><strong
          ><u
            ><span style={{"color": "#0563c1"}}
              >Amazon Payments, Inc. Customer Agreement</span
            ></u
          ></strong
        ></a
      >, the amended and restated version will be posted here:&nbsp;<a
        href="https://pay.amazon.com/help/201212430"
        style={{"textDecoration": "none"}}
        ><strong
          ><u
            ><span style={{"color": "#0563c1"}}
              >Amazon Payments, Inc. Customer Agreement</span
            ></u
          ></strong
        ></a
      >. Such amendments to the Amazon Payments, Inc. Customer Agreement are
      effective as of the date of posting. Your continued use of Amazon Pay on
      your Store after the amended Amazon Payments, Inc. Customer Agreement is
      posted constitutes your agreement to, and acceptance of, the amended
      Amazon Payments, Inc. Customer Agreement. If you do not agree to any
      changes to the Amazon Payments, Inc. Customer Agreement, deactivate your
      Amazon Pay account and do not continue to use Amazon Pay on your Store.
    </li>
  </ol>
  <p className="about-text"><strong>2.9 Domain Names</strong></p>
  <ol>
    <li>
      Upon purchasing a domain name through <strong>Kuchbhiorder</strong>,
      domain registration will be preset to automatically renew each year so
      long as your <strong>Kuchbhiorder</strong> Account remains active. You
      acknowledge that it is your sole responsibility to deactivate the
      auto-renewal function should you choose to do so.
    </li>
  </ol>
  <p className="about-text"><strong>3. Kuchbhiorder</strong> <strong>Rights</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    <strong>Kuchbhiorder</strong> has the right to control who we make our
    Services available to and we can modify them at any time. We also have the
    right to refuse or remove Materials from any part of the Services, including
    your Store. We may offer our services to your competitors, but we will never
    share your confidential information with them in doing so. In the event of
    an ownership dispute over a <strong>Kuchbhiorder</strong> Account, we can
    freeze the <strong>Kuchbhiorder</strong> Account or transfer it to the
    rightful owner, as determined by us.
  </p>
  <ol>
    <li>
      The Services have a range of features and functionalities. Not all
      Services or features will be available to all Merchants at all times and
      we are under no obligation to make any Services or features available in
      any jurisdiction. Except where prohibited in these Terms of Service or by
      applicable law, we reserve the right to modify the Services or any part
      thereof for any reason, without notice and at any time.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> does not pre-screen Materials and it is in
      our sole discretion to refuse or remove any Materials from any part of the
      Services, including if we determine in our sole discretion that the goods
      or services that you offer through the Services, or the Materials uploaded
      or posted to the Services, violate these Terms of Service.
    </li>
    <li>
      Verbal or written abuse of any kind (including threats of abuse or
      retribution) of any <strong>Kuchbhiorder</strong> employee, member, or
      officer will result in immediate Account termination.
    </li>
    <li>
      We reserve the right to provide our Services to your competitors and make
      no promise of exclusivity. You further acknowledge and agree that
      <strong>Kuchbhiorder</strong> employees and contractors may also be
      <strong>Kuchbhiorder</strong> customers or merchants and that they may
      compete with you, although they may not use your Confidential Information
      (as defined in Section 6) in doing so.
    </li>
    <li>
      In the event of a dispute regarding Account ownership, we reserve the
      right to request documentation to determine or confirm Account ownership.
      Documentation may include, but is not limited to, a scanned copy of your
      business license, government issued photo ID, the last four digits of the
      credit card on file, or confirmation of your status as an employee of an
      entity.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> reserves the right to determine, in our sole
      discretion, rightful Account ownership and transfer an Account to the
      rightful Store Owner. If we are unable to reasonably determine the
      rightful Store Owner, without prejudice to our other rights and remedies,
      <strong>Kuchbhiorder</strong> reserves the right to temporarily suspend or
      disable an Account until resolution has been determined between the
      disputing parties.
    </li>
  </ol>
  <p className="about-text"><strong>4. Your Responsibilities</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    You are responsible for your <strong>Kuchbhiorder</strong> Store, the goods
    or services you sell, and your relationship with your customers, not us. If
    you access the<strong>&nbsp;Kuchbhiorder</strong> API, your use of the
    <strong>Kuchbhiorder</strong> API is subject to the API Terms.
  </p>
  <ol type="1">
    <li>
      You acknowledge and agree to provide public-facing contact information, a
      refund policy and order fulfilment timelines on your
      <strong>Kuchbhiorder</strong> Store.
    </li>
    <li>
      You acknowledge and agree that the Services are not a marketplace, and any
      contract of sale made through the Services is directly between you and the
      customer. You are the seller of record for all items you sell through the
      Services. You are responsible for the creation and operation of your
      <strong>Kuchbhiorder</strong> Store, your Materials, the goods and
      services that you may sell through the Services, and all aspects of the
      transactions between you and your customer(s). This includes, but is not
      limited to, authorizing the charge to the customer in respect of the
      customer&rsquo;s purchase, refunds, returns, fulfilling any sales or
      customer service, fraudulent transactions, required legal disclosures,
      regulatory compliance, alleged or actual violation of applicable laws
      (including but not limited to consumer protection laws in any jurisdiction
      where you offer products or services for sale), or your breach of these
      Terms of Service. You represent and warrant that your Store, your
      Materials and the goods and services you sell through the Services will be
      true, accurate, and complete, and will not violate any applicable laws,
      regulations or rights of third parties. For the avoidance of doubt,
      <strong>Kuchbhiorder</strong> will not be the seller or merchant or record
      and will have no responsibility for your Store or items sold to customers
      through the Services.
    </li>
    <li>
      You are solely responsible for the goods or services that you may sell
      through the Services (including description, price, fees, tax that you
      calculate, defects, required legal disclosures, regulatory compliance,
      offers or promotional content), including compliance with any applicable
      laws or regulations.
    </li>
    <li>
      You may not use the <strong>Kuchbhiorder</strong> Services for any illegal
      or unauthorized purpose nor may you, in the use of the Service, violate
      any laws in your jurisdiction (including but not limited to copyright
      laws), the laws applicable to you in your customer&rsquo;s jurisdiction,
      or the laws of India. You will comply with all applicable laws, rules and
      regulations (including but not limited to obtaining and complying with the
      requirements of any license or permit that may be necessary to operate
      your store or that may be held by you) in your use of the Service and your
      performance of obligations under the Terms of Service.
    </li>
    <li>
      The&nbsp;<strong>API Terms</strong> govern your access to and use of the
      <strong>Kuchbhiorder</strong> API (as defined therein). You are solely
      responsible for the activity that occurs using your API Credentials (as
      defined in the API Terms) and for keeping your API Credentials secure.
    </li>
    <li>
      You agree to use <strong>Kuchbhiorder</strong> Checkout for any sales
      associated with your online store. &ldquo;<strong>Kuchbhiorder</strong>
      <strong>Checkout</strong>&rdquo; means
      <strong>Kuchbhiorder</strong> checkout experience that allows Customers to
      enter their shipping information and payment details after adding item(s)
      to their cart and before placing an order, including checkouts that occur
      through the <strong>Kuchbhiorder</strong> Checkout API.
    </li>
  </ol>
  <p className="about-text"><strong>5. Payment of Fees and Taxes</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    A valid payment method (such as a credit card) must remain on file to pay
    all service fees, including the subscription, transaction and additional
    fees required for all stores. You will be billed for your Subscription Fees
    every 30 days. Any Transaction Fees or Additional Fees will be charged to
    your payment method. If we are unable to process payment of Fees using your
    payment method, we may make subsequent attempts to process payment of Fees
    using your payment method. If payment of Fees is unsuccessful within 28 days
    of our initial attempt to process payment, <strong>Kuchbhiorder</strong> may
    freeze your store. You are responsible for all taxes relating to your Store
    or use of the Services. You may be required to remit Taxes to
    <strong>Kuchbhiorder</strong> or to self-remit to your local taxing
    authority. No refunds.
  </p>
  <ol type="1">
    <li>
      You will pay the Fees applicable to your subscription to Online Service
      and/or POS Services (&ldquo;<strong>Subscription Fees</strong>&rdquo;) and
      any other applicable fees, including but not limited to applicable fees
      relating to the value of sales made through your Store when using all
      payment providers other than <strong>Kuchbhiorder</strong> Payments
      (&ldquo;<strong>Transaction Fees</strong>&rdquo;), and any fees relating
      to your purchase or use of any products or services such as
      <strong>Kuchbhiorder</strong> Payments, POS Equipment, shipping, apps,
      Themes, domain names, Experts Marketplace, or Third Party Services
      (&ldquo;<strong>Additional Fees</strong>&rdquo;). Together, the
      Subscription Fees, Transaction Fees and the Additional Fees are referred
      to as the &ldquo;<strong>Fees</strong>&rdquo;.
    </li>
    <li>
      You must keep a valid payment method on file with us to pay for all
      incurred and recurring Fees. <strong>Kuchbhiorder</strong> will charge
      applicable Fees to any valid payment method that you authorize
      (&ldquo;<strong>Authorized Payment Method</strong>&rdquo;), and
      <strong>Kuchbhiorder</strong> will continue to charge the Authorized
      Payment Method for applicable Fees until the Services are terminated, and
      any and all outstanding Fees have been paid in full. Unless otherwise
      indicated, all Fees and other charges are in Indian rupees, and all
      payments will be in Indian currency.
    </li>
    <li>
      Subscription Fees are paid in advance and will be billed in 30 day
      intervals (each such date, a &ldquo;<strong>Billing Date</strong>&rdquo;).
      Transaction Fees and Additional Fees will be charged from time to time at
      <strong>Kuchbhiorder</strong> discretion. You will be charged on each
      Billing Date for all outstanding Fees that have not previously been
      charged. Fees will appear on an invoice, which will be sent to the Store
      Owner via the Primary Email Address provided. As well, an invoice will
      appear on the Account page of your Store&rsquo;s administrative console.
      Users have approximately two weeks to bring up and settle any issues with
      the billing of Subscription Fees.
    </li>
    <li>
      If we are not able to process payment of Fees using an Authorized Payment
      Method, we may make subsequent attempts to process payment using any
      Authorized Payment Method. If we are unable to successfully process
      payment of Fees using an Authorized Payment Method within 28 days of our
      initial attempt, we may suspend and revoke access to your Account and the
      Services. Your Account will be reactivated upon your payment of any
      outstanding Fees, plus the Fees applicable to your next billing cycle. You
      may not be able to access your Account or your storefront during any
      period of suspension. If the outstanding Fees remain unpaid for 60 days
      following the date of suspension, <strong>Kuchbhiorder</strong> reserves
      the right to terminate your Account in accordance with Section 14.
    </li>
    <li>
      All Fees are exclusive of applicable federal, provincial, state, local or
      other governmental sales, goods and harmonized or other taxes, fees or
      charges now in force or enacted in the future
      (&ldquo;<strong>Taxes</strong>&rdquo;).
    </li>
    <li>
      You are responsible for all applicable Taxes that arise from or as a
      result of your subscription to or purchase of
      <strong>Kuchbhiorder</strong> products and services. To the extent that
      <strong>Kuchbhiorder</strong> charges these Taxes, they are calculated
      using the tax rates that apply based on the billing address you provide to
      us. Such amounts are in addition to the Fees for such products and
      services and will be billed to your Authorized Payment Method. If you are
      exempt from payment of such Taxes, you must provide us with evidence of
      your exemption, which in some jurisdictions includes an original
      certificate that satisfies applicable legal requirements attesting to
      tax-exempt status. Tax exemption will only apply from and after the date
      we receive evidence satisfactory to <strong>Kuchbhiorder</strong> of your
      exemption. If you are not charged Taxes by <strong>Kuchbhiorder</strong>,
      you are responsible for determining if Taxes are payable, and if so,
      self-remitting Taxes to the appropriate tax authorities in your
      jurisdiction.
    </li>
    <li>
      For the avoidance of doubt, all sums payable by you to
      <strong>Kuchbhiorder</strong> under these Terms of Service will be paid
      free and clear of any deductions or withholdings whatsoever. Other than
      Taxes charged by <strong>Kuchbhiorder</strong> to you and remitted to the
      appropriate tax authorities on your behalf, any deductions or withholdings
      that are required by law will be borne by you and paid separately to the
      relevant taxation authority. <strong>Kuchbhiorder</strong> will be
      entitled to charge the full amount of Fees stipulated under these Terms of
      Service to your Authorized Payment Method ignoring any such deduction or
      withholding that may be required.
    </li>
    <li>
      You are solely responsible for determining, collecting, withholding,
      reporting, and remitting applicable taxes, duties, fees, surcharges and
      additional charges that arise from or as a result of any sale on your
      <strong>Kuchbhiorder</strong> Store or your use of the Services. The
      Services are not a marketplace. Any contract of sale made through the
      Services is directly between you and the customer.
    </li>
    <li>
      You must maintain an accurate location in the administrative console of
      your <strong>Kuchbhiorder</strong> Store. If you change jurisdictions you
      must promptly update your location in the administrative console.
    </li>
    <li><strong>Kuchbhiorder</strong> does not provide refunds.</li>
  </ol>
  <p className="about-text"><strong>6. Confidentiality</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    Both you and <strong>Kuchbhiorder</strong> agree to use the Confidential
    Information of the other only to perform the obligations in these Terms of
    Service. Confidential Information must be protected and respected.
  </p>
  <ol type="1" style={{"margin": "0pt", "paddingLeft": "0pt"}}>
    <li>
      &ldquo;<strong>Confidential Information</strong>&rdquo; will include, but
      will not be limited to, any and all information associated with a
      party&rsquo;s business and not publicly known, including specific business
      information, technical processes and formulas, software, customer lists,
      prospective customer lists, names, addresses and other information
      regarding customers and prospective customers, product designs, sales,
      costs (including any relevant processing fees), price lists, and other
      unpublished financial information, business plans and marketing data, and
      any other confidential and proprietary information, whether or not marked
      as confidential or proprietary. <strong>Kuchbhiorder</strong> Confidential
      Information includes all information that you receive relating to us, or
      to the Services, that is not known to the general public including
      information related to our security program and practices.
    </li>
    <li>
      Each party agrees to use the other party&rsquo;s Confidential Information
      solely as necessary for performing its obligations under these Terms of
      Service and in accordance with any other obligations in these Terms of
      Service including this Section 6. Each party agrees that it will take all
      reasonable steps, at least substantially equivalent to the steps it takes
      to protect its own proprietary information, to prevent the duplication,
      disclosure or use of any such Confidential Information, other than (i) by
      or to its employees, agents and subcontractors who must have access to
      such Confidential Information to perform such party&rsquo;s obligations
      hereunder, who each will treat such Confidential Information as provided
      herein, and who are each subject to obligations of confidentiality to such
      party that are at least as stringent as those contained herein; or (ii) as
      required by any law, regulation, or order of any court of proper
      jurisdiction over the parties and the subject matter contained in these
      Terms of Service, provided that, if legally permitted, the receiving party
      will give the disclosing party prompt written notice and use commercially
      reasonable efforts to ensure that such disclosure is accorded confidential
      treatment. Confidential Information will not include any information that
      the receiving party can prove: (A) was already in the public domain, or
      was already known by or in the possession of the receiving party, at the
      time of disclosure of such information; (B) is independently developed by
      the receiving party without use of or reference to the other party&rsquo;s
      Confidential Information, and without breaching any provisions of these
      Terms of Service; or (C) is thereafter rightly obtained by the receiving
      party from a source other than the disclosing party without breaching any
      provision of these Terms of Service.
    </li>
  </ol>
  <p className="about-text"><strong>7. Limitation of Liability and Indemnification</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    We are not responsible for damages or lawsuits that arise if you break the
    law, breach this agreement or go against the rights of a third party. The
    Service is provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo;
    basis. We provide no warranties and our liability is limited in the event of
    errors or interruptions.
  </p>
  <ol type="1" style={{"margin": "0pt", "paddingLeft": "0pt"}}>
    <li>
      You expressly understand and agree that, to the extent permitted by
      applicable laws, <strong>Kuchbhiorder</strong> and its suppliers will not
      be liable for any direct, indirect, incidental, special, consequential or
      exemplary damages, including but not limited to, damages for loss of
      profits, goodwill, use, data or other intangible losses arising out of or
      relating to the use of or inability to use the Service or these Terms of
      Service (however arising, including negligence).
    </li>
    <li>
      You agree to indemnify and hold us and (as applicable) our parent,
      subsidiaries, affiliates, <strong>Kuchbhiorder</strong> partners,
      officers, directors, agents, employees, and suppliers harmless from any
      claim or demand, including reasonable attorneys&rsquo; fees, made by any
      third party due to or arising out of (a) your breach of these Terms of
      Service or the documents it incorporates by reference (b) or your
      violation of any law or the rights of a third party; or (c) any aspect of
      the transaction between you and your Customer, including but not limited
      to refunds, fraudulent transactions, alleged or actual violation of
      applicable laws (including but not limited to Federal and State consumer
      protection laws), or your breach of the Terms of Service.
    </li>
    <li>
      You will be responsible for any breach of the Terms of Service by your
      affiliates, agents or subcontractors and will be liable as if it were your
      own breach.
    </li>
    <li>
      Your use of the Services is at your sole risk. The Services are provided
      on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis without any
      warranty or condition, express, implied or statutory.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> does not warrant that the Services will be
      uninterrupted, timely, secure, or error-free.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> does not warrant that the results that may
      be obtained from the use of the Services will be accurate or reliable.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> is not responsible for any of your tax
      obligations or liabilities related to the use of
      <strong>Kuchbhiorder</strong> Services.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> does not warrant that the quality of any
      products, services, information, or other materials purchased or obtained
      by you through the Services will meet your expectations, or that any
      errors in the Services will be corrected.
    </li>
  </ol>
  <p className="about-text"><strong>8. Intellectual Property and Your Materials</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    Anything you upload remains yours (if it was yours) and is your
    responsibility, but <strong>Kuchbhiorder</strong> can use and publish the
    things you upload. Anything uploaded to
    <strong>Kuchbhiorder</strong> remains the property and responsibility of its
    initial owner. However, <strong>Kuchbhiorder</strong> will receive a license
    of materials published through our platform that we may use to operate and
    promote our Services.
  </p>
  <p className="about-text"><strong>8.1 Your Materials</strong></p>
  <ol type="1" style={{"margin": "0pt" ,"paddingLeft": "0pt"}}>
    <li>
      We do not claim ownership of the Materials you provide to
      <strong>Kuchbhiorder</strong>; however, we do require a license to those
      Materials. You grant <strong>Kuchbhiorder</strong> a non-exclusive,
      transferable, sub-licensable, royalty-free, worldwide right and license to
      host, use, distribute, expose, modify, run, copy, store, publicly perform,
      communicate to the public (including by telecommunication), broadcast,
      reproduce, make available, display, and translate, and create derivative
      works of any Materials provided by you in connection with the Services. We
      may use our rights under this license to operate, provide, and promote the
      Services and to perform our obligations and exercise our rights under the
      Terms of Service. You represent, warrant, and agree that you have all
      necessary rights in the Materials to grant this license. You irrevocably
      waive any and all moral rights you may have in the Materials in favour of
      <strong>Kuchbhiorder</strong> and agree that this waiver may be invoked by
      anyone who obtains rights in the materials through
      <strong>Kuchbhiorder</strong>, including anyone to whom
      <strong>Kuchbhiorder</strong> may transfer or grant (including by way of
      license or sublicense) any rights in the Materials.
    </li>
    <li>
      If you owned the Materials before providing them to
      <strong>Kuchbhiorder</strong> then, despite uploading them to your
      <strong>Kuchbhiorder</strong> Store they remain yours, subject to any
      rights or licenses granted in the Terms of Service or elsewhere. You can
      remove your <strong>Kuchbhiorder</strong> Store at any time by deleting
      your Account. Removing your <strong>Kuchbhiorder</strong> Store does not
      terminate any rights or licenses granted to the Materials that
      <strong>Kuchbhiorder</strong> requires to exercise any rights or perform
      any obligations that arose during the Term.
    </li>
    <li>
      You agree that <strong>Kuchbhiorder</strong> can, at any time, review and
      delete any or all of the Materials submitted to the Services, although
      <strong>Kuchbhiorder</strong> is not obligated to do so.
    </li>
    <li>
      You grant <strong>Kuchbhiorder</strong> a non-exclusive, transferable,
      sub-licensable, royalty-free, worldwide right and license to use the
      names, trademarks, service marks and logos associated with your Store
      (&ldquo;<strong>Your Trademarks</strong>&rdquo;) to operate, provide, and
      promote the Services and to perform our obligations and exercise our
      rights under the Terms of Service. This license will survive any
      termination of the Terms of Service solely to the extent that
      <strong>Kuchbhiorder</strong> requires the license to exercise any rights
      or perform any obligations that arose during the Term.
    </li>
  </ol>
  <p style={{"marginTop":" 0pt", "marginBottom": "8pt"}} className="about-text">
    <strong>8.2 Kuchbhiorder</strong> <strong>Intellectual Property</strong>
  </p>
  <ol type="1" style={{"margin": "0pt" ,"paddingLeft": "0pt"}}>
    <li>
      You agree not to purchase, register, or use search engine or other
      pay-per-click keywords (such as Google Ads), trademarks, email addresses,
      social media names, or domain names (including without limitation
      top-level domains, sub-domains, and page URLs) that use or include
      <strong>Kuchbhiorder</strong> or <strong>Kuchbhiorder</strong> Trademarks
      or that use or include any terms that may be confusing with the
      <strong>Kuchbhiorder</strong> Trademarks.
    </li>
    <li>
      You acknowledge and agree that the Terms of Service do not give you any
      right to implement <strong>Kuchbhiorder</strong> patents.
    </li>
  </ol>
  <p className="about-text"><strong>9. Additional Services</strong></p>
  <p className="about-text"><strong>9.1 POS Services</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    If you use our POS Services, you must use the same payment processor that
    you use for the Online Services. You can cancel your POS Services at any
    time and still keep your Online Services active. While we take data security
    very seriously, we can&rsquo;t guarantee that all transmissions using the
    POS Equipment are 100% secure. All transactions transmitted using the POS
    Equipment are at your own risk. Remember that the POS equipment is
    purchased, not leased. Our POS Equipment return policy applies to all POS
    equipment, except Chip &amp; Swipe Reader, Swipe (Audio Jack) and physical
    gift cards
  </p>
  <ol>
    <li>
      In addition to the terms applicable to Services generally, the following
      terms apply to your access and use of the POS Services. The POS Services
      include the <strong>Kuchbhiorder</strong> POS software (&ldquo;<strong
        >POS Software</strong
      >&rdquo;), the <strong>Kuchbhiorder</strong> POS website, located
      at&nbsp;<strong>https://kuchbhiorder.com/pos</strong>, programs,
      documentation, apps, tools, internet-based services and components,
      <strong>Kuchbhiorder</strong> POS hardware (&ldquo;<strong
        >POS Equipment</strong
      >&rdquo;) and any updates thereto provided to you by
      <strong>Kuchbhiorder</strong>.
    </li>
    <li>
      Access to and use of the POS Services requires that you have an active and
      valid Account.
    </li>
    <li>
      If your POS Services are enabled with
      <strong>Kuchbhiorder</strong> Payments, you cannot concurrently use any
      other payment processing service. The payment gateway used for your POS
      Services must be the same as that used for your Online Services, if
      applicable.
    </li>
    <li>
      You can terminate your POS Services without terminating your Account or
      any other Services you subscribe to, and in such case, you will continue
      to be billed, and will pay for, the Services (other than the terminated
      POS Services).
    </li>
    <li>
      You agree to use the POS Services in accordance with all procedures that
      may be provided by <strong>Kuchbhiorder</strong> from time to time.
    </li>
    <li>
      While the POS Services use encryption technology, and the law generally
      prohibits third parties from monitoring transmission, we cannot guarantee
      security with respect to the connection to the POS Services, and we will
      not be liable for any unauthorized access to or use of data transmitted
      via the POS Services.
    </li>
    <li>
      POS Equipment is purchased, and not leased. Purchase of the POS Equipment
      is subject to the POS Equipment Agreement,&nbsp;<strong
        >POS Equipment Agreement and Return Policy</strong
      >, applicable to your country. Upon payment by you for the POS Equipment,
      and confirmation to you of shipment of the POS equipment, you will acquire
      ownership of and title to the hardware components of the POS Equipment,
      and you will be licensed to access and use all POS Software installed in
      the POS Equipment (and any updates thereto), on a limited, non-exclusive,
      revocable, non-sublicensable, non-transferable basis, solely for the
      purposes of using the POS Services. Returns and refunds are not available
      for the Chip &amp; Swipe Reader, Swipe (Audio Jack) and physical gift
      cards purchased from the <strong>Kuchbhiorder</strong> Hardware Store.
    </li>
    <li>
      The Fees for POS Services will be determined based on the number of
      locations at which you are using the POS Services. Locations are defined
      by business address and are used for associating orders with a specific
      business address. Locations are added through the
      <strong>Kuchbhiorder</strong> web administrative console associated with
      your Account. The number of locations using POS Services will also be
      detected by us and billed accordingly as part of your regular billing
      cycle. In the event of any discrepancy between the number of locations
      added by you through the <strong>Kuchbhiorder</strong> web administrative
      console and the number of locations detected by
      <strong>Kuchbhiorder</strong>, the number of locations detected by
      <strong>Kuchbhiorder</strong> will be deemed to be correct.
    </li>
  </ol>
  <p className="about-text"><strong>9.2 Kuchbhiorder</strong> <strong>Shipping</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    If you use <strong>Kuchbhiorder</strong> Shipping, you must comply with the
    <strong>Kuchbhiorder</strong> Shipping Terms of Service.
  </p>
  <ol>
    <li  style={{"margin": "31.35pt" ,"marginBottom": "8pt","paddingLeft": "4.65pt"}}>
      You can purchase shipping labels from within your
      <strong>Kuchbhiorder</strong> administrative console (&ldquo;<strong
        >Kuchbhiorder</strong
      >
      <strong>Shipping</strong>&rdquo;) for delivery of goods purchased from you
      from your <strong>Kuchbhiorder</strong> Store. In addition to these Terms
      of Service, your access to and use of
      <strong>Kuchbhiorder</strong> Shipping is subject to the
      <strong>Kuchbhiorder</strong> Shipping Terms of Service.
    </li>
  </ol>
  <p className="about-text">&nbsp;</p>
  <p className="about-text"><strong>9.3 SMS Messaging</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    When you use our SMS Services, you have to comply with our terms, the
    acceptable use policies of the SMS providers, and the law.
  </p>
  <ol type="1">
    <li>
      The Services allow you to send certain communications to your customers by
      short message service (SMS) messaging (for example, sending order
      confirmation notifications via SMS) (the &quot;SMS Services&quot;). You
      will only use the SMS Services in compliance with these Terms of Service,
      any other applicable terms (including Nexmo Inc.&apos;s&nbsp;<a
        href="https://qa-www.nexmo.com/acceptable-use"
        style={{"textDecoration": "none"}}
        ><strong
          ><u
            ><span >Acceptable Use Policy</span></u
          ></strong
        ></a
      >
      and Twilio&rsquo;s&nbsp;<a
        href="https://www.twilio.com/legal/aup"
       
        ><strong
          ><u
            ><span >Acceptable Use Policy</span></u
          ></strong
        ></a
      >, as may be amended from time to time), and the laws of the jurisdiction
      from which you send messages, and in which your messages are received.
    </li>
  </ol>
  <p className="about-text"><strong>9.4 Kuchbhiorder</strong> <strong>Email</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    By using the Email Services, you agree that
    <strong>Kuchbhiorder</strong> may use certain tools to ensure the content of
    the emails sent through the service is safe and compliant with our policies.
  </p>
  <p className="about-text">
    You may generate or send email from your Account using the
    <strong>Kuchbhiorder</strong> email services (the &ldquo;<strong
      >Email Services</strong
    >&rdquo;). In addition to the terms applicable to the Services generally
    (including <strong>Kuchbhiorder</strong> Acceptable Use Policy, and Privacy
    Policy), the following terms apply to your access and use of the Email
    Services:
  </p>
  <ol type="1">
    <li>
      <strong>Kuchbhiorder</strong> employs certain controls to scan the content
      of emails you deliver using the Email Services prior to delivery
      (&ldquo;<strong>Content Scanning</strong>&rdquo;). Such Content Scanning
      is intended to limit spam, phishing, or other malicious content that
      contravenes these Terms of Service, or
      <strong>Kuchbhiorder</strong> Acceptable Use Policy (collectively,
      &ldquo;<strong>Threats</strong>&rdquo;). By using the Email Services, you
      explicitly grant <strong>Kuchbhiorder</strong> the right to employ such
      Content Scanning. <strong>Kuchbhiorder</strong> does not warrant that the
      Email Services will be free from Threats, and each
      <strong>Kuchbhiorder</strong> merchant is responsible for all content
      generated by their respective Stores.
    </li>
    <li>
      BY GENERATING OR SENDING EMAIL THROUGH THE EMAIL SERVICES, YOU AGREE TO
      COMPLY WITH THE FOLLOWING REQUIREMENTS (THE &ldquo;<strong
        >EMAIL SERVICES REQUIREMENTS</strong
      >&rdquo;). <strong>Kuchbhiorder</strong>, OR ITS THIRD PARTY PROVIDERS,
      MAY SUSPEND OR TERMINATE YOUR ACCESS TO AND USE OF THE EMAIL SERVICES IF
      YOU DO NOT COMPLY WITH THE EMAIL SERVICES REQUIREMENTS.
    </li>
    <li>
      Your use of the Email Services must comply with all applicable laws.
      Examples of applicable laws include laws relating to spam or unsolicited
      commercial email (&ldquo;<strong>UCE</strong>&rdquo;), privacy, security,
      obscenity, defamation, intellectual property, pornography, terrorism,
      homeland security, gambling, child protection, and other applicable laws.
      It is your responsibility to know and understand the laws applicable to
      your use of the Email Services and the emails you generate or send through
      the Email Services.
    </li>
    <li>
      Your use of the Email Services must comply with
      <strong>Kuchbhiorder</strong> Privacy Policy. It is your responsibility to
      read and understand the Privacy Policy applicable to your use of the Email
      Services and the emails you generate or send through the Email Services.
    </li>
    <li>
      You will use commercially reasonable efforts not to send sensitive
      personal data, including information regarding an individual&rsquo;s
      medical or health condition, race or ethnic origin, political opinions,
      religious or philosophical beliefs, or other sensitive data (collectively,
      &ldquo;<strong>Sensitive Data</strong>&rdquo;) through the Email Services.
      It is your responsibility to read and understand your obligations in
      relation to Sensitive Data.
    </li>
    <li>
      Your use of the Email Services must follow all applicable guidelines
      established by <strong>Kuchbhiorder</strong>. The guidelines below are
      examples of practices that may violate the Email Services Requirements
      when generating, or sending emails through the Email Services:
      <ol
        type="1"
        
      >
        <li>
          using non-permission based email lists (i.e., lists in which each
          recipient has not explicitly granted permission to receive emails from
          you by affirmatively opting-in to receive those emails);
        </li>
        <li>using purchased or rented email lists;</li>
        <li>
          using third party email addresses, domain names, or mail servers
          without proper permission;
        </li>
        <li>
          sending emails to non-specific addresses (e.g.,&nbsp;<a
            href="mailto:webmaster@domain.com"
          
            ><strong
              ><u
                ><span >webmaster@domain.com</span></u
              ></strong
            ></a
          >
          or&nbsp;<a href="mailto:info@domain.com" 
            ><strong
              ><u
                ><span >info@domain.com</span></u
              ></strong
            ></a
          >);
        </li>
        <li>
          sending emails that result in an unacceptable number of spam or UCE
          complaints (even if the emails themselves are not actually spam or
          UCE);
        </li>
        <li>
          failing to include a working
          &ldquo;<strong>unsubscribe</strong>&rdquo; link in each email that
          allows the recipient to remove themselves from your mailing list;
        </li>
        <li>
          failing to comply with any request from a recipient to be removed from
          your mailing list within 10 days of receipt of the request;
        </li>
        <li>
          disguising the origin or subject matter of any email or falsifying or
          manipulating the originating email address, subject line, headers, or
          transmission path information for any email;
        </li>
        <li>
          failing to include in each email a link to the then-current privacy
          policy applicable to that email;
        </li>
        <li>
          failing to include in each email your valid physical mailing address
          or a link to that information; or
        </li>
        <li>
          including &ldquo;<strong>junk mail,</strong>&rdquo; &ldquo;<strong
            >chain letters,</strong
          >&rdquo; &ldquo;<strong>pyramid schemes,</strong>&rdquo; incentives
          (e.g., coupons, discounts, awards, or other incentives) or other
          material in any email that encourages a recipient to forward the Email
          to another recipient.
        </li>
      </ol>
    </li>
    <li>
      If you or a customer knows of or suspects any violations of the Email
      Services Requirements, please notify
      <strong>Kuchbhiorder</strong> at&nbsp;<a
        href="mailto:info@kuchbhiorder.com"
      
        ><strong
          ><u
            ><span >info@kuchbhiorder.com</span></u
          ></strong
        ></a
      >. <strong>Kuchbhiorder</strong> will determine compliance with the Email
      Services Requirements in its discretion.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> Email Services utilize Third Party
      Providers, including SendGrid (a Twilio Company). Your use of the Email
      Services is subject to SendGrid&rsquo;s&nbsp;<a
        href="https://www.twilio.com/legal/aup"
      
        ><strong
          ><u
            ><span >Acceptable Use Policy</span></u
          ></strong
        ></a
      >
      as it may be amended by SendGrid from time to time.
    </li>
  </ol>
  <p className="about-text">
    <strong>9.5 Third Party Services, Experts, and Experts Marketplace</strong>
  </p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    We are not responsible for third party services, so use them at your own
    risk. If you choose to use Third Party Services on the
    <strong>Kuchbhiorder</strong> platform, you consent to us sharing your data
    (and potentially the data of your Customers) to those services. If you use
    Third Party Services you agree that we do not provide a warranty, so get
    advice beforehand.
  </p>
  <ol type="1">
    <li>
      <strong>Kuchbhiorder</strong> may from time to time recommend, provide you
      with access to, or enable third party software, applications
      (&ldquo;<strong>Apps</strong>&rdquo;), products, services or website links
      (collectively, &ldquo;<strong>Third Party Services</strong>&rdquo;) for
      your consideration or use, including via the
      <strong>Kuchbhiorder</strong> App Store, or Experts Marketplace. Such
      Third Party Services are made available only as a convenience, and your
      purchase, access or use of any such Third Party Services is solely between
      you and the applicable third party services provider (&ldquo;<strong
        >Third Party Provider</strong
      >&rdquo;). In addition to these Terms of Service, you also agree to be
      bound by the additional service-specific terms applicable to services you
      purchase from, or that are provided by, Third Party Providers.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> Experts is an online directory of
      independent third parties (&ldquo;<strong>Experts</strong>&rdquo;) that
      can help you build and operate your <strong>Kuchbhiorder</strong> Store.
    </li>
    <li>
      You can engage and work with an Expert directly or through Experts
      Marketplace. Experts Marketplace provides you with recommendations on
      Experts that can assist you with different aspects of your Store. Using
      Experts Marketplace, you can find, hire, and communicate with Experts
      directly from your Account admin.
    </li>
    <li>
      Any use by you of Third Party Services offered through the Services,
      <strong>Kuchbhiorder</strong> Experts, Experts Marketplace or
      <strong>Kuchbhiorder</strong> website is entirely at your own risk and
      discretion, and it is your responsibility to read the terms and conditions
      and/or privacy policies applicable to such Third Party Services before
      using them. In some instances, <strong>Kuchbhiorder</strong> may receive a
      revenue share from Third Party Providers that
      <strong>Kuchbhiorder</strong> recommends to you or that you otherwise
      engage through your use of the Services,
      <strong>Kuchbhiorder</strong> Experts, Experts Marketplace or
      <strong>Kuchbhiorder</strong> website.
    </li>
    <li>
      We do not provide any warranties or make representations to you with
      respect to Third Party Services. You acknowledge that
      <strong>Kuchbhiorder</strong> has no control over Third Party Services and
      will not be responsible or liable to you or anyone else for such Third
      Party Services. The availability of Third Party Services on
      <strong>Kuchbhiorder</strong> websites, including the
      <strong>Kuchbhiorder</strong> App Store or Experts Marketplace, or the
      integration or enabling of such Third Party Services with the Services
      does not constitute or imply an endorsement, authorization, sponsorship,
      or affiliation by or with <strong>Kuchbhiorder</strong>.
      <strong>Kuchbhiorder</strong> does not guarantee the availability of Third
      Party Services and you acknowledge that <strong>Kuchbhiorder</strong> may
      disable access to any Third Party Services at any time in its sole
      discretion and without notice to you. <strong>Kuchbhiorder</strong> is not
      responsible or liable to anyone for discontinuation or suspension of
      access to, or disablement of, any Third Party Service.
      <strong>Kuchbhiorder</strong> strongly recommends that you seek specialist
      advice before using or relying on Third Party Services, to ensure they
      will meet your needs. In particular, tax calculators should be used for
      reference only and not as a substitute for independent tax advice, when
      assessing the correct tax rates you should charge to your customers.
    </li>
    <li>
      If you install or enable a Third Party Service for use with the Services,
      you grant us permission to allow the applicable Third Party Provider to
      access your data and other Materials and to take any other actions as
      required for the interoperation of the Third Party Service with the
      Services, and any exchange of data or other Materials or other interaction
      between you and the Third Party Provider is solely between you and such
      Third Party Provider. <strong>Kuchbhiorder</strong> is not responsible for
      any disclosure, modification or deletion of your data or other Materials,
      or for any corresponding losses or damages you may suffer, as a result of
      access by a Third Party Service or a Third Party Provider to your data or
      other Materials. Google Maps is a Third Party Service that is used within
      the Services. Your use of the Services is subject to your acceptance of
      the Google Maps and Earth Enterprise Universal Acceptable Use Policy,
      Google Maps and Google Earth Additional Terms of Service and Google
      Privacy Policy as it may be amended by Google from time to time.
    </li>
    <li>
      You acknowledge and agree that: (i) by submitting a request for assistance
      or other information through Experts Marketplace, you consent to being
      contacted by one or more Experts at the Primary Email Address (or such
      other email address provided by you) as well as the applicable user email
      address; and (ii) <strong>Kuchbhiorder</strong> will receive all email
      communications exchanged via Experts Marketplace or in any reply emails
      (each a &ldquo;<strong>Reply</strong>&rdquo;) that originate from Experts
      Marketplace (directly or indirectly) between yourself and Experts. You
      further agree that <strong>Kuchbhiorder</strong> may share your contact
      details and the background information that you submit via the Experts
      Marketplace with Experts. Experts may require access to certain admin
      pages on your <strong>Kuchbhiorder</strong> Store. You choose the pages
      that the Experts can access.
    </li>
    <li>
      The relationship between you and any Third Party Provider is strictly
      between you and such Third Party Provider, and
      <strong>Kuchbhiorder</strong> is not obligated to intervene in any dispute
      arising between you and a Third Party Provider.
    </li>
    <li>
      Under no circumstances will <strong>Kuchbhiorder</strong> be liable for
      any direct, indirect, incidental, special, consequential, punitive,
      extraordinary, exemplary or other damages whatsoever, that result from any
      Third Party Services or your contractual relationship with any Third Party
      Provider, including any Expert. These limitations will apply even if
      <strong>Kuchbhiorder</strong> has been advised of the possibility of such
      damages. The foregoing limitations will apply to the fullest extent
      permitted by applicable law.
    </li>
    <li>
      You agree to indemnify and hold us and (as applicable) our parent,
      subsidiaries, affiliates, <strong>Kuchbhiorder</strong> partners,
      officers, directors, agents, employees, and suppliers harmless from any
      claim or demand, including reasonable attorneys&rsquo; fees, arising out
      of your use of a Third Party Service or your relationship with a Third
      Party Provider.
    </li>
  </ol>
  <p className="about-text"><strong>10. Feedback and Reviews</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    We welcome customer feedback but are under no obligation to ensure that
    ideas and suggestions regarding our Services or the services of third
    parties remain confidential and we can use the feedback in any way we want.
  </p>
  <p className="about-text">
    <strong>Kuchbhiorder</strong> welcomes any ideas and/or suggestions
    regarding improvements or additions to the Services. Under no circumstances
    will any disclosure of any idea, suggestion or related material or any
    review of the Services, Third Party Services or any Third Party Provider
    (collectively, &ldquo;<strong>Feedback</strong>&rdquo;) to
    <strong>Kuchbhiorder</strong> be subject to any obligation of
    confidentiality or expectation of compensation. By submitting Feedback to
    <strong>Kuchbhiorder</strong> (whether submitted directly to
    <strong>Kuchbhiorder</strong> or posted on any
    <strong>Kuchbhiorder</strong> hosted forum or page), you waive any and all
    rights in the Feedback and that <strong>Kuchbhiorder</strong> is free to
    implement and use the Feedback if desired, as provided by you or as modified
    by <strong>Kuchbhiorder</strong>, without obtaining permission or license
    from you or from any third party. Any reviews of a Third Party Service or
    Third Party Provider that you submit to <strong>Kuchbhiorder</strong> must
    be accurate to the best of your knowledge, and must not be illegal, obscene,
    threatening, defamatory, invasive of privacy, infringing of intellectual
    property rights, or otherwise injurious to third parties or objectionable.
    <strong>Kuchbhiorder</strong> reserves the right (but not the obligation) to
    remove or edit Feedback of Third Party Services or Third Party Providers,
    but does not regularly inspect posted Feedback.
  </p>
  <p className="about-text"><strong>12. Privacy and Data Protection</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    <strong>Kuchbhiorder</strong> use and collection of personal information is
    governed by our Privacy Policy. <strong>Kuchbhiorder</strong> use and
    collection of customer personal information is further governed by our Data
    Processing Addendum.
  </p>
  <ol type="1">
    <li>
      <strong>Kuchbhiorder</strong> is firmly committed to protecting the
      privacy of your personal information and the personal information of your
      customers. By using the Service, you acknowledge and agree that
      <strong>Kuchbhiorder</strong> collection, usage and disclosure of this
      personal information is governed by our&nbsp;<strong
        >Privacy Policy</strong
      >.
    </li>
    <li>
      To the extent that <strong>Kuchbhiorder</strong> processes personal
      information of your customers as a &ldquo;data processor&rdquo; or
      &ldquo;service provider&rdquo; under certain data privacy or protection
      laws, <strong>Kuchbhiorder</strong> collection and use of personal
      information is also subject to our&nbsp;<strong
        >Data Processing Addendum</strong
      >.
    </li>
  </ol>
  <p className="about-text"><strong>13. Kuchbhiorder</strong> <strong>Contracting Party</strong></p>
  <ol type="1">
    <li>
      If the billing address of your Store is located in the:
      <ol type="1">
        <li>
          &ldquo;<strong>Kuchbhiorder</strong>
          <strong>Contracting Party</strong>&rdquo; means
          <strong>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED</strong>.,
          First floor, Above CSB Bank, Opp. Thyssenkrupp Industry, Near Ambedkar
          Chowk, Pimpri, Pune - Maharashtra, India-411018
        </li>
        <li>
          The parties irrevocably and unconditionally submit to the exclusive
          jurisdiction of the courts of the Province of Ontario with respect to
          any dispute or claim arising out of or in connection with the Terms of
          Service. The India Convention on Contracts for the International Sale
          of Goods will not apply to these Terms of Service and is hereby
          expressly excluded.
        </li>
      </ol>
    </li>
  </ol>
  <p className="about-text"><strong>14. Term and Termination</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    To initiate a termination, you must contact&nbsp;<strong>Support</strong>.
    <strong>Kuchbhiorder</strong> will respond with specific information
    regarding the termination process for your account. Once termination is
    confirmed, domains purchased through <strong>Kuchbhiorder</strong> will no
    longer be automatically renewed. If you cancel in the middle of your billing
    cycle, you&rsquo;ll have one last email invoice.
  </p>
  <p className="about-text">We may terminate your account at any time.</p>
  <ol type="1">
    <li>
      The term of these Terms of Service will begin on the date of your
      completed registration for use of a Service and continue until terminated
      by us or by you, as provided below (the
      &ldquo;<strong>Term</strong>&rdquo;).
    </li>
    <li>
      You may cancel your Account and terminate the Terms of Service at any time
      by contacting&nbsp;<strong>Kuchbhiorder</strong>
      <strong>Support</strong> and then following the specific instructions
      indicated to you in <strong>Kuchbhiorder</strong> response.
    </li>
    <li>
      Without limiting any other remedies, we may suspend or terminate your
      Account or the Terms of Service for any reason, without notice and at any
      time (unless otherwise required by law), including if we suspect that you
      (by conviction, settlement, insurance or escrow investigation, or
      otherwise) have engaged in fraudulent activity in connection with the use
      of the Services. Termination of the Terms of Service will be without
      prejudice to any rights or obligations which arose prior to the date of
      termination.
    </li>
    <li>
      Upon termination of the Services by either party for any reason:
      <ol
        type="1"
        
      >
        <li>
          <strong>Kuchbhiorder</strong> will cease providing you with the
          Services and you will no longer be able to access your Account;
        </li>
        <li>
          unless otherwise provided in the Terms of Service, you will not be
          entitled to any refunds of any Fees, pro rata or otherwise;
        </li>
        <li>
          any outstanding balance owed to <strong>Kuchbhiorder</strong> for your
          use of the Services through the effective date of such termination
          will immediately become due and payable in full; and
        </li>
        <li>your <strong>Kuchbhiorder</strong> Store will be taken offline.</li>
      </ol>
    </li>
    <li>
      If you purchased a domain name through <strong>Kuchbhiorder</strong>, upon
      cancellation your domain will no longer be automatically renewed.
      Following termination, it will be your sole responsibility to handle all
      matters related to your domain with the domain provider.
    </li>
    <li>
      If there are any outstanding Fees owed by you at the date of termination
      of the Service, you will receive one final invoice via email. Once that
      invoice has been paid in full, you will not be charged again.
    </li>
  </ol>
  <p className="about-text"><strong>15. Modifications</strong></p>
  <p className="about-text"><strong>Which means</strong></p>
  <p className="about-text">
    If we make a material change to the Terms of Service that adversely affects
    your rights under the Terms of Service or your use of our Services in a
    material way, we will notify you in advance (unless the change relates to
    legal requirements or to prevent abuse of our Services, among other things).
  </p>
  <ol type="1">
    <li>
      We reserve the right, in our sole and absolute discretion, to update or
      change any portion of the Terms of Service at any time. We will provide
      you with reasonable advance notice of changes to the Terms of Service that
      materially adversely affect your use of the Services or your rights under
      the Terms of Service by sending an email to the Primary Email Address,
      providing notice through the <strong>Kuchbhiorder</strong> administrative
      console, or by similar means. However, <strong>Kuchbhiorder</strong> may
      make changes that materially adversely affect your use of the Services or
      your rights under the Terms of Service at any time and with immediate
      effect (i) for legal, regulatory, fraud and abuse prevention, or security
      reasons; or (ii) to restrict products or activities that we deem unsafe,
      inappropriate, or offensive. Unless we indicate otherwise in our notice
      (if applicable), any changes to the Terms of Service will be effective
      immediately upon posting of such updated terms at this location. Your
      continued access to or use of the Services after we provide such notice,
      if applicable, or after we post such updated terms, constitutes your
      acceptance of the changes and consent to be bound by the Terms of Service
      as amended. If you do not agree to the amended Terms of Service, you must
      stop accessing and using the Services.
    </li>
    <li>
      <strong>Kuchbhiorder</strong> may change the Fees for the Services from
      time-to-time. We will provide you with 30 days advanced notice prior to
      any changes in Fees by sending an email to the Primary Email Account,
      providing notice through the <strong>Kuchbhiorder</strong> administrative
      console, or by similar means. <strong>Kuchbhiorder</strong> will not be
      liable to you or to any third party for any modification, price change,
      suspension or discontinuance of the Services (or any part thereof).
    </li>
  </ol>
  <p className="about-text"><strong>16. General Conditions</strong></p>
  <ol type="1">
    <li>
      The Terms of Service, including the documents it incorporates by
      reference, constitute the entire agreement between you and
      <strong>Kuchbhiorder</strong> and govern your use of the Services and your
      Account, superseding any prior agreements between you and
      <strong>Kuchbhiorder</strong> (including, but not limited to, any prior
      versions of the Terms of Service).
    </li>
    <li>
      The failure of <strong>Kuchbhiorder</strong> to exercise or enforce any
      right or provision of the Terms of Service will not constitute a waiver of
      such right or provision. If any provision of the Terms of Service,
      including all terms and conditions and other documents it incorporates by
      reference, is held by a court of competent jurisdiction to be contrary to
      law, such provision will be changed and interpreted so as to best
      accomplish the objectives of the original provision to the fullest extent
      allowed by law, and the remaining provision of the Terms of Service will
      remain in full force and effect.
    </li>
    <li>
      Save for <strong>Kuchbhiorder</strong> and its affiliates, you or anyone
      accessing <strong>Kuchbhiorder</strong> Services pursuant to these Terms
      of Service, unless otherwise provided in these Terms of Service, no person
      or entity who is not a party to these Terms of Service will have any right
      to enforce any term of these Terms of Service, regardless of whether such
      person or entity has been identified by name, as a member of a class or as
      answering a particular description. For the avoidance of doubt, this will
      not affect the rights of any permitted assignee or transferee of these
      Terms.
    </li>
    <li>
      The Terms of Service may be available in languages other than English. To
      the extent of any inconsistencies or conflicts between these English Terms
      of Service and <strong>Kuchbhiorder</strong> Terms of Service available in
      another language, the most current English version of the Terms of
      Service. Any disputes arising out of these Terms of Service will be
      resolved in English unless otherwise determined by
      <strong>Kuchbhiorder</strong> (acting in its sole discretion) or as
      required by applicable law.
    </li>
    <li>
      All the terms and provisions of the Terms of Service will be binding upon
      and inure to the benefit of the parties to the Terms of Service and to
      their respective heirs, successors, permitted assigns and legal
      representatives. <strong>Kuchbhiorder</strong> will be permitted to assign
      these Terms of Service without notice to you or consent from you. You will
      have no right to assign or otherwise transfer the Terms of Service, or any
      of your rights or obligations hereunder, to any third party without
      <strong>Kuchbhiorder</strong> prior written consent, to be given or
      withheld in <strong>Kuchbhiorder</strong> sole discretion.
    </li>
    <li>
      If any provision, or portion of the provision, in these Terms of Service
      is, for any reason, held to be invalid, illegal or unenforceable in any
      respect, then such invalidity, illegality or unenforceability will not
      affect any other provision (or the unaffected portion of the provision) of
      the Terms of Service, and the Terms of Service will be construed as if
      such invalid, illegal or unenforceable provision, or portion of the
      provision, had never been contained within the Terms of Service.
    </li>
  </ol>
  <div>
    <hr size="0" />
  </div>
  <p className="about-text"><strong>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED</strong>.,</p>
  <p className="about-text">&nbsp;First floor, Above CSB Bank, Opp.</p>
  <p className="about-text">Thyssenkrupp Industry, Near Ambedkar Chowk, Pimpri,</p>
  <p className="about-text">Pune - Maharashtra, India-411018</p>
</div>
<p className="about-text">
  <a href="https://wordtohtml.net" target="_blank"
    >Converted to HTML with WordToHTML.net</a
  >
</p>

    </div>

<p ></p >
<p ></p >
<p ></p >
<p ></p >

</section>

</div>   

        </article>
    </main>

   












   
</div>
















































  )
}

export default TandC