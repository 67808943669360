
// import React, { useState } from 'react';
// import './RequestQuote.css'

// const RequestQuote = () => {
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         companyName: '',
//         annualRevenue: '',
//         contactNumber: '',
//         country: '',
//         businessType: '',
//         products: '',
//         otherBusinessType: '',
//         otherProducts: '',
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await fetch('https://kborder.onrender.com/request-quote', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(formData),
//             });

//             const data = await response.json();
//             alert(data.message);
//         } catch (error) {
//             console.error('Error submitting quote:', error);
//         }
//     };

//     return (
//         <div>
//             <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                     <label>First Name</label>
//                     <input 
//                         type="text" 
//                         name="firstName" 
//                         className="form-control" 
//                         onChange={handleChange} 
//                         required 
//                     />
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Last Name</label>
//                     <input 
//                         type="text" 
//                         name="lastName" 
//                         className="form-control" 
//                         onChange={handleChange} 
//                         required 
//                     />
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Business Email Address</label>
//                     <input 
//                         type="email" 
//                         name="email" 
//                         className="form-control" 
//                         onChange={handleChange} 
//                         required 
//                     />
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Company Name</label>
//                     <input 
//                         type="text" 
//                         name="companyName" 
//                         className="form-control" 
//                         onChange={handleChange} 
//                         required 
//                     />
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Annual Revenue</label>
//                     <select 
//                         name="annualRevenue" 
//                         className="form-select" 
//                         onChange={handleChange} 
//                         required
//                     >
//                         <option value="">Select...</option>
//                         <option value="Less than $100k">Less than $100k</option>
//                         <option value="$100k - $500k">$100k - $500k</option>
//                         <option value="$500k - $1M">$500k - $1M</option>
//                         <option value="$1M - $5M">$1M - $5M</option>
//                         <option value="More than $5M">More than $5M</option>
//                     </select>
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Contact Number</label>
//                     <input 
//                         type="text" 
//                         name="contactNumber" 
//                         className="form-control" 
//                         onChange={handleChange} 
//                         required 
//                     />
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Country</label>
//                     <select 
//                         name="country" 
//                         className="form-select" 
//                         onChange={handleChange} 
//                         required
//                     >
//                         <option value="">Select...</option>
//                         <option value="United States">United States</option>
//                         <option value="Canada">Canada</option>
//                         <option value="United Kingdom">United Kingdom</option>
//                         <option value="Australia">Australia</option>
//                         <option value="India">India</option>
//                     </select>
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Business Type</label>
//                     <select 
//                         name="businessType" 
//                         className="form-select" 
//                         onChange={handleChange} 
//                         required
//                     >
//                         <option value="">Select...</option>
//                         <option value="Retailer">Retailer</option>
//                         <option value="Wholesaler">Wholesaler</option>
//                         <option value="Manufacturer">Manufacturer</option>
//                         <option value="Distributor">Distributor</option>
//                         <option value="Service provider">Service provider</option>
//                         <option value="Other">Other</option>
//                     </select>
//                     {formData.businessType === 'Other' && (
//                         <input 
//                             type="text" 
//                             name="otherBusinessType" 
//                             className="form-control mt-2" 
//                             placeholder="Please specify..." 
//                             onChange={handleChange} 
//                         />
//                     )}
//                 </div>
//                 <div className="mb-3">
//                     <label className="form-label">Products You Deal In</label>
//                     <select 
//                         name="products" 
//                         className="form-select" 
//                         onChange={handleChange} 
//                         required
//                     >
//                         <option value="">Select...</option>
//                         <option value="Electronics">Electronics</option>
//                         <option value="Fashion & Apparel">Fashion & Apparel</option>
//                         <option value="Home & Kitchen">Home & Kitchen</option>
//                         <option value="Beauty & Personal Care">Beauty & Personal Care</option>
//                         <option value="Groceries & Food Items">Groceries & Food Items</option>
//                         <option value="Furniture">Furniture</option>
//                         <option value="Books & Stationery">Books & Stationery</option>
//                         <option value="Sports & Fitness">Sports & Fitness</option>
//                         <option value="Automotive">Automotive</option>
//                         <option value="Others">Others</option>
//                     </select>
//                     {formData.products === 'Others' && (
//                         <input 
//                             type="text" 
//                             name="otherProducts" 
//                             className="form-control mt-2" 
//                             placeholder="Please specify..." 
//                             onChange={handleChange} 
//                         />
//                     )}
//                 </div>
//                 <button type="submit" className="btn btn-primary">Submit</button>
//             </form>
//         </div>
//     );
// };

// export default RequestQuote;



import React, { useState } from 'react';
import './RequestQuote.css'

const RequestQuote = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        annualRevenue: '',
        contactNumber: '',
        country: '',
        businessType: '',
        products: '',
        otherBusinessType: '',
        otherProducts: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://kborder.onrender.com/request-quote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            alert(data.message);
        } catch (error) {
            console.error('Error submitting quote:', error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label  className="form-label">First Name</label>
                    <input 
                        type="text" 
                        name="firstName" 
                        className="form-control" 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Last Name</label>
                    <input 
                        type="text" 
                        name="lastName" 
                        className="form-control" 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Business Email Address</label>
                    <input 
                        type="email" 
                        name="email" 
                        className="form-control" 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Company Name</label>
                    <input 
                        type="text" 
                        name="companyName" 
                        className="form-control" 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                {/* <div className="mb-3 dropdown">
                    <label className="form-label">Annual Revenue</label>
                    <select 
                        name="annualRevenue" 
                        className="form-select" 
                        onChange={handleChange} 
                        required
                    >
                        <option value="">Select...</option>
                        <option value="Less than $100k">Less than ₹100k</option>
                        <option value="$100k - $500k">₹100k - ₹500k</option>
                        <option value="$500k - $1M">₹500k - ₹1M</option>
                        <option value="$1M - $5M">₹1M - ₹5M</option>
                        <option value="More than $5M">More than ₹5M</option>
                    </select>
                </div> */}
                <div className="mb-3">
                    <label className="form-label">Contact Number</label>
                    <input 
                        type="text" 
                        name="contactNumber" 
                        className="form-control" 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                {/* <div className="mb-3  dropdown">
                    <label className="form-label">Country</label>
                    <select 
                        name="country" 
                        className="form-select" 
                        onChange={handleChange} 
                        required
                    >
                        <option value="">Select...</option>
                        <option value="United States">United States</option>
                        <option value="Canada">Canada</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Australia">Australia</option>
                        <option value="India">India</option>
                    </select>
                </div> */}
                <div className="mb-3  dropdown">
                    <label className="form-label">Business Type</label>
                    <select 
                        name="businessType" 
                        className="form-select" 
                        onChange={handleChange} 
                        required
                    >
                        <option value="">Select...</option>
                        <option value="Retailer">Retailer</option>
                        <option value="Wholesaler">Wholesaler</option>
                        <option value="Manufacturer">Manufacturer</option>
                        <option value="Distributor">Distributor</option>
                        <option value="Service provider">Service provider</option>
                        <option value="Other">Other</option>
                    </select>
                    {formData.businessType === 'Other' && (
                        <input 
                            type="text" 
                            name="otherBusinessType" 
                            className="form-control mt-2" 
                            placeholder="Please specify..." 
                            onChange={handleChange} 
                        />
                    )}
                </div>
                <div className="mb-3  dropdown">
                    <label className="form-label">Products You Deal In</label>
                    <select 
                        name="products" 
                        className="form-select" 
                        onChange={handleChange} 
                        required
                    >
                        <option value="">Select...</option>
                        <option value="Electronics">Electronics</option>
                        <option value="Fashion & Apparel">Fashion & Apparel</option>
                        <option value="Home & Kitchen">Home & Kitchen</option>
                        <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                        <option value="Groceries & Food Items">Groceries & Food Items</option>
                        <option value="Furniture">Furniture</option>
                        <option value="Books & Stationery">Books & Stationery</option>
                        <option value="Sports & Fitness">Sports & Fitness</option>
                        <option value="Automotive">Automotive</option>
                        <option value="Others">Others</option>
                    </select>
                    {formData.products === 'Others' && (
                        <input 
                            type="text" 
                            name="otherProducts" 
                            className="form-control mt-2" 
                            placeholder="Please specify..." 
                            onChange={handleChange} 
                        />
                    )}
                </div>
                <button type="submit" className="btn btn-primary" style={{width:250}}>Submit</button>
            </form>
        </div>
    );
};

export default RequestQuote;